import React, { useState, useEffect, useCallback, useContext } from "react";
import { ReactComponent as Logo } from "../public/images/sortPyramids.svg";
import { ReactComponent as LoadingLogo } from "../public/images/loadingblue.svg";
import Portfolio from "./portfolioDiv";
import PortfolioChart from "./portfolioChart";

import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";
import { UseUserPortfolio } from "../web3/UserPortfolio";
import SubmitDiv from "./submitDiv";

export default function RektData(props) {
  const { connected, currAddress, currChainId, wrongNetwork, comingSoon } =
    UseInfoUser();

  //   const { rektPower, getRektPower, unRektness, setUnRektness } =
  //     UseUserPortfolio();
  const [unRektness, setUnRektness] = useState(null);

  const [userInfo, setUserInfo] = useState(null);
  // const [rektData, setRektData] = useState(null);
  const [rektTxns, setrektTxns] = useState([]);

  const [parsingData, setParsingData] = useState(false);
  const [prepDivs, setPrepDivs] = useState(false);

  const [nbrekt, setNbRekt] = useState(0);
  const [nbmadeit, setNbMadeit] = useState(0);

  function removeDuplicates(array) {
    let input = {};
    let output = [];

    for (let i = 0; i < array.length; i++) {
      let el = array[i];

      if (!(el in input)) {
        input[el] = true;
        output.push(el);
      }
    }
    return output;
  }

  function getDuplicates(arr) {
    const hashTable = {};
    const duplicate = [];
    arr.forEach((item) => {
      if (hashTable[item.hash]) {
        if (hashTable[item.hash] === 1) {
          duplicate.push(item);
        }
        hashTable[item.hash] = hashTable[item.hash] + 1;
      } else {
        hashTable[item.hash] = 1;
      }
    });
    return duplicate;
  }

  function ParseAllTxns(duplicates, allElligibleTxns, currAddress) {
    var resarray = [];
    let res = duplicates
      ? duplicates.forEach((itemDuplicate) => {
          var count = 0;
          let ress = allElligibleTxns
            ? allElligibleTxns.forEach((itemEachTxn) => {
                if (
                  itemEachTxn.hash === itemDuplicate.hash &&
                  itemEachTxn.tokenSymbol.toUpperCase() !==
                    itemDuplicate.tokenSymbol.toUpperCase()
                ) {
                  var arraytemp = [];
                  var valItem = 0;
                  if (itemEachTxn.tokenSymbol === "USDC") {
                    valItem = 1e-6 * itemEachTxn.value;
                  } else if (itemEachTxn.tokenSymbol === "$SAVIOR") {
                    valItem = parseInt(itemEachTxn.value);
                  } else {
                    valItem = 1e-18 * itemEachTxn.value;
                  }
                  var valDupe;
                  if (itemDuplicate.tokenSymbol === "USDC") {
                    valDupe = 1e-6 * itemDuplicate.value;
                  } else if (itemDuplicate.tokenSymbol === "$SAVIOR") {
                    valDupe = parseInt(itemDuplicate.value);
                  } else {
                    valDupe = 1e-18 * itemDuplicate.value;
                  }
                  // console.log(val);
                  // console.log(
                  //   itemDuplicate.tokenSymbol,
                  //   itemEachTxn.tokenSymbol
                  // );
                  // console.log(val);
                  if (!isNaN(valDupe) && !isNaN(valItem)) {
                    arraytemp.splice(0, 0, valItem);
                    arraytemp.splice(1, 0, itemEachTxn.tokenSymbol);
                    arraytemp.splice(2, 0, valDupe);
                    arraytemp.splice(3, 0, itemDuplicate.tokenSymbol);
                    arraytemp.splice(4, 0, itemDuplicate.blockNumber);
                    arraytemp.splice(5, 0, itemDuplicate.hash);
                    resarray.push(arraytemp);
                  }
                }
              })
            : null;
        })
      : null;
    // remove elements with length != 6
    resarray = resarray.reduce((p, c) => (c.length === 6 && p.push(c), p), []);
    // console.log(resarray);
    return resarray;
  }
  function getRektTransactions(parsedTxn) {
    var txnHashSold = {};
    var useBreak = false;
    var rektedTxns = [];
    var txnID = 0;
    var runningAmountBuy = 0;
    var runningAmountSell = 0;
    var nbRekt = 0;
    var nbMadeit = 0;
    var ratioTotal = 0;

    var arr = parsedTxn
      ? parsedTxn.forEach((txn) => {
          var bought = txn[3]; //token symbol bought
          var boughtAmt = txn[2]; //token amount
          var paid = txn[1]; // Paid token
          var paidAmt = txn[0]; //amount paid
          // if (paid !== USDC) {
          // }

          runningAmountBuy = 0;
          useBreak = false;
          if (
            !bought.toUpperCase().includes("USDC") &&
            !bought.toUpperCase().includes("USDT") &&
            !bought.toUpperCase().includes("DAI")
          ) {
            var arry = parsedTxn
              ? parsedTxn.forEach((dupe) => {
                  if (useBreak) {
                    return;
                  }
                  var sold = dupe[1];
                  var soldAmt = dupe[0]; //token amount
                  var back = dupe[3]; // back token
                  var backAmt = dupe[2]; //amount back
                  if (
                    txn[5].toLowerCase() != dupe[5].toLowerCase() && //not same trnx hash
                    dupe[4] > txn[4] && // block is new than original buy
                    bought === sold &&
                    paid === back &&
                    bought !== paid &&
                    bought != back &&
                    sold !== back &&
                    sold != paid &&
                    boughtAmt > 0 &&
                    soldAmt > 0 &&
                    paidAmt > 0 &&
                    backAmt > 0 &&
                    !bought.toLowerCase().includes("lp") &&
                    !sold.toLowerCase().includes("lp") &&
                    !paid.toLowerCase().includes("lp") &&
                    !back.toLowerCase().includes("lp")
                    // &&
                    // !bought.includes("-") &&
                    // !sold.includes("-")
                    // &&
                    // !paid.includes("-") &&
                    // !back.includes("-")
                  ) {
                    // console.log("Buy", bought, boughtAmt, paid, paidAmt);
                    // console.log("Sell", sold, soldAmt, back, backAmt);
                    if (dupe[5] in txnHashSold) {
                    } else {
                      // console.log("Buy", txn);
                      // console.log("Sell", dupe);
                      if (boughtAmt < soldAmt) {
                        useBreak = true;
                        if (runningAmountSell + boughtAmt >= soldAmt) {
                          txnHashSold[dupe[5]] = dupe[5]; // add to hashtable
                          runningAmountSell = 0;
                        }
                        if (runningAmountSell + boughtAmt <= soldAmt) {
                          runningAmountSell += boughtAmt;

                          var buyRatio = parseFloat(paidAmt / boughtAmt);
                          var sellRatio = parseFloat(backAmt / soldAmt);

                          var rekttemp = [];
                          rekttemp.splice(0, 0, paidAmt); //Amount paid
                          rekttemp.splice(1, 0, paid); //Symbol of token used to buy with
                          rekttemp.splice(2, 0, boughtAmt); //For how much token bought
                          rekttemp.splice(3, 0, bought); //of which coin symbol

                          rekttemp.splice(4, 0, soldAmt); //Amount sold
                          rekttemp.splice(5, 0, sold); //of which coin symbol
                          rekttemp.splice(6, 0, backAmt); //For how much Amount gotten back
                          rekttemp.splice(7, 0, back); //Symbol of token used to buy with in the first place

                          rekttemp.splice(8, 0, parseFloat(buyRatio)); //ratio buy
                          rekttemp.splice(9, 0, parseFloat(sellRatio)); //ratio sell

                          rekttemp.splice(10, 0, txn[5]); // Txn hash buy
                          rekttemp.splice(11, 0, dupe[5]); // Txn hash sell

                          var ratio = parseFloat(sellRatio / buyRatio);
                          ratioTotal += ratio;
                          var rekt;

                          var percent = 0;
                          if (ratio >= 1) {
                            rekt = "madeit";
                            nbMadeit++;
                            percent = 100 * ratio;
                          } else {
                            rekt = "rekt";
                            nbRekt++;
                            percent = 100 * (1 - ratio);
                          }
                          var sellCost;
                          var buyCost;

                          sellCost = boughtAmt * sellRatio;
                          buyCost = boughtAmt * buyRatio;

                          var unRektusPotential = sellCost - buyCost; //either -ve or positive
                          // percent = (unRektusPotential / buyCost) * 100;
                          rekttemp.splice(12, 0, ratio); //
                          rekttemp.splice(13, 0, unRektusPotential); // Txn
                          rekttemp.splice(14, 0, rekt); //
                          // rekttemp.splice(16, 0, currAddress); // Txn
                          rekttemp.splice(15, 0, txnID++);
                          rekttemp.splice(16, 0, percent);
                          rektedTxns.push(rekttemp);
                        }
                      } else {
                        if (runningAmountBuy + soldAmt >= boughtAmt) {
                          useBreak = true;
                        }
                        if (runningAmountBuy + soldAmt <= boughtAmt) {
                          runningAmountBuy += soldAmt;
                          txnHashSold[dupe[5]] = dupe[5];

                          var buyRatio = parseFloat(paidAmt / boughtAmt);
                          var sellRatio = parseFloat(backAmt / soldAmt);

                          var rekttemp = [];
                          rekttemp.splice(0, 0, paidAmt); //Amount paid
                          rekttemp.splice(1, 0, paid); //Symbol of token used to buy with
                          rekttemp.splice(2, 0, boughtAmt); //For how much token bought
                          rekttemp.splice(3, 0, bought); //of which coin symbol

                          rekttemp.splice(4, 0, soldAmt); //Amount sold
                          rekttemp.splice(5, 0, sold); //of which coin symbol
                          rekttemp.splice(6, 0, backAmt); //For how much Amount gotten back
                          rekttemp.splice(7, 0, back); //Symbol of token used to buy with in the first place

                          rekttemp.splice(8, 0, parseFloat(buyRatio)); //ratio buy
                          rekttemp.splice(9, 0, parseFloat(sellRatio)); //ratio sell

                          rekttemp.splice(10, 0, txn[5]); // Txn hash buy
                          rekttemp.splice(11, 0, dupe[5]); // Txn hash sell

                          var ratio = parseFloat(sellRatio / buyRatio);
                          ratioTotal += ratio;
                          var rekt;
                          var percent = 0;

                          if (ratio >= 1) {
                            rekt = "madeit";
                            nbMadeit++;
                            percent = 100 * ratio;
                          } else {
                            rekt = "rekt";
                            nbRekt++;
                            percent = 100 * (1 - ratio);
                          }

                          var sellCost;
                          var buyCost;

                          sellCost = soldAmt * sellRatio;
                          buyCost = soldAmt * buyRatio;

                          var unRektusPotential = sellCost - buyCost; //either -ve or positive
                          // percent = (unRektusPotential / buyCost) * 100;
                          rekttemp.splice(12, 0, ratio); //
                          rekttemp.splice(13, 0, unRektusPotential); // Txn
                          rekttemp.splice(14, 0, rekt); //
                          rekttemp.splice(15, 0, txnID++);
                          // rekttemp.splice(16, 0, currAddress); // Txn
                          rekttemp.splice(16, 0, percent);
                          rektedTxns.push(rekttemp);
                        }
                      }
                    }
                  }
                })
              : null;
          }
        })
      : null;
    // console.log(rektedTxns.length);
    setrektTxns(rektedTxns);
    setNbRekt(nbRekt);
    setNbMadeit(nbMadeit);
    setUnRektness({
      nbRekt: nbRekt,
      nbMadeit: nbMadeit,
      ratioTotal: ratioTotal,
    });
    // console.log(rektedTxns);
    return rektedTxns;
  }

  function AutoParse() {
    // console.log("Auto Parsing");
    props.setHowRektPressed(1);
    var arrCurrent = [];
    var resarray = [];
    var col = 0;
    var row = 0;
    var idx = 0;
    // console.log(data.data.result);
    let arr = props.data?.data?.result
      ? props.data.data.result.forEach((element) => {
          arrCurrent = element;
          let arrr = props.data?.data?.result
            ? props.data.data.result.forEach((elementSub) => {
                idx++;
                if (elementSub.hash === element.hash) {
                  resarray.push(elementSub);
                  row++;
                }
              })
            : null;
        })
      : null;
    // console.log(resarray);
    // trim doubles:
    var allElligibleTxns = resarray.filter((v, i, a) => a.indexOf(v) === i);
    // console.log(allElligibleTxns);
    var duplicates = getDuplicates(allElligibleTxns);
    // console.log(duplicates);
    var parsedTxn = ParseAllTxns(duplicates, allElligibleTxns);
    // var parsedTxn = ParseAllTxnsDoge(allElligibleTxns);
    // console.log(parsedTxn);
    // var removeDup = removeDuplicates(parsedTxn);
    const rektedtxns = getRektTransactions(parsedTxn);
    // console.log("already submitted", submissionFound);
    setParsingData(false);
    return rektedtxns;
    // setRektDivs(getRektDivs());
  }

  useEffect(() => {
    if (props.goAutoparse === true) {
      // run AutoParse() if a submission has been confirmed
      const autoParseData = async () => {
        setParsingData(true);
        // console.log("go auto parsing");
        const userInfo = await props.handleCheckSubmission();
        setUserInfo(userInfo);
        AutoParse();
        setPrepDivs(true);
        props.setGoAutoParse(false);
      };
      autoParseData().catch(console.error);
    }
  }, [props.goAutoparse]);

  return (
    <>
      {connected && !wrongNetwork && !comingSoon && (
        <>
          {/* GET MY UNREKTUS POTENTIAL BUTTON */}
          {!props.data ? (
            <>
              {props.queriesEnable === false && (
                <div className="flex flex-col items-center justify-center mt-8 ">
                  <button
                    className="z-10 relative flex justify-center px-6 py-2  hover:bg-[#d430b0]:opacity-50 text-white text-2xl border-2 border-pink-600 rounded-lg"
                    onClick={() => {
                      props.setQueriesEnable(true);
                    }}
                  >
                    Get my Urektus potential
                  </button>
                  <div className="z-0 absolute rounded-lg h-[60px] w-[320px] animate-pulse border-4 blur border-[#d430b0]"></div>
                </div>
              )}
              {/* GET API TXNs DATA */}
              {props.queriesEnable === true && (
                <div className="flex flex-col items-center justify-center">
                  <div className=" flex flex-col items-center justify-center  ">
                    <LoadingLogo className="mt-12 h-[100px] w-[100px] animate-spin"></LoadingLogo>
                    <h2 className="text-white text-[16px]">
                      Calculating your Unrektability...
                    </h2>
                    {props.isLoading ? (
                      <h2 className="text-white text-[16px]"></h2>
                    ) : (
                      props.errorTxn && (
                        <h2 className="text-white text-[16px]">
                          Experiencing network issues?
                        </h2>
                      )
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {props.howRektPressed > 0 ? (
                <>
                  {parsingData ? (
                    <div className="flex flex-col items-center justify-center">
                      <div className=" flex flex-col items-center justify-center  ">
                        <LoadingLogo className="mt-12 h-[100px] w-[100px] animate-spin"></LoadingLogo>
                        <h2 className="text-white text-[16px]">
                          Loading Data...
                        </h2>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="flex-col items-center justify-center">
                        <PortfolioChart
                          chain={props.dataChain}
                          unRektness={unRektness}
                        />
                      </div>
                      <div className="relative w-full flex h-20 mt-2 mb-6 first-letter:items-center justify-center">
                        <div className="absolute flex flex-col items-center bg-gradient-to-r from-white to-[#26f5d8] via-blue-300 rounded-lg py-1 px-1">
                          <div className="z-10 relative flex flex-col items-center w-full border-[8px] bg-[#110a26] border-[#110a26] rounded-lg">
                            {nbrekt > 0 ? (
                              <>
                                <p className="text-xl md:text-3xl font-bold text-white flex flex-row items-center bg-[#110a26]">
                                  YOU GOT&nbsp;
                                  <span className="text-[#ff31e9]">
                                    {" "}
                                    REKT!&nbsp;
                                  </span>
                                </p>
                                <p className="text-xl md:text-3xl font-bold text-white flex flex-row items-center bg-[#110a26]">
                                  Unrekt yourself on
                                  <span className="text-[#ff31e9] px-2">
                                    {nbrekt}
                                  </span>
                                  <span>trades!</span>
                                </p>
                              </>
                            ) : (
                              <>
                                <h1 className="text-xl md:text-2xl font-bold text-white text-center ">
                                  You have not been REKT (yet), WELLDONE !
                                </h1>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <SubmitDiv
                    prepDivs={prepDivs}
                    setPrepDivs={setPrepDivs}
                    rektTxns={rektTxns}
                    userInfo={userInfo}
                    handleSubmitPopUp={props.handleSubmitPop}
                  ></SubmitDiv>
                </>
              ) : (
                <>
                  <div className="flex flex-col items-center justify-center ">
                    <div className="z-0 absolute rounded-3xl  animate-pulse  h-[118px] w-[350px] border-8 blur border-[#d430b0]"></div>
                    <button
                      className="z-10  mt-4 mb-4 rounded-lg  bg-gradient-to-r from-[#D02374]/80 to-[#9434E7]/70 focus:ring focus:ring-pink-600 px-14 py-4 text-white font-bold "
                      onClick={() => {
                        props.setHowRektPressed(1);
                        setParsingData(true);
                        props.setGoAutoParse(true);
                      }}
                    >
                      <label className="z-20 text-[30px] font-bold animate-pulse hover:cursor-pointer">
                        ARE YOU REKT?
                      </label>
                      <p className="text-white text-[20px]">Click</p>
                      <p className="text-white text-[15px]"></p>
                    </button>
                  </div>
                  {/* <div className='relative w-full flex h-20 mt-6 mb-6 first-letter:items-center justify-center'>
												<div className='absolute flex flex-col items-center bg-gradient-to-r from-white to-[#26f5d8] via-blue-300 rounded-lg py-1 px-1'>
													<div className='z-10 relative flex flex-col items-center justify-center w-full border-[8px] bg-[#110a26] border-[#110a26] rounded-lg'>
														<span className='text-xl md:text-3xl flex uppercase text-center items-center justify-center bg-[#110a26]'>
															<p className='text-white'>
																did you get&nbsp;
																<span className='text-[#ff31e9] uppercase'>
																	{" "}
																	REKT?&nbsp;
																</span>
																verify below!
															</p>
														</span>
													</div>
												</div>
											</div> */}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
