import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as LoadingLogo } from "../public/images/loadingblue.svg";
import { UseContractInfo } from "../web3/ContractContext";

export default function PickWinner() {
  const {
    sessionEnded,
    winnerPicked,
    isLoadingStatus,
    checkStatus,
    handlePickWinner,
  } = UseContractInfo();

  const [transactionSuccess, setTransactionSuccess] = useState(null);
  const [gettingReceipt, setGettingReceipt] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function pickWinner() {
    const { status, message } = await handlePickWinner();
    setGettingReceipt(false);
    setTransactionSuccess(status);
    setErrorMessage(message);
  }
  useEffect(() => {
    if (transactionSuccess === true) {
      const getNewStatus = async () => {
        await checkStatus();
      };
      getNewStatus();
    }
  }, [transactionSuccess]);

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        {!isLoadingStatus && !winnerPicked && sessionEnded && (
          <>
            <div className="flex items-center justify-center">
              {/* {prizeValue > 0 && ( */}
              <button
                className="w-full mt-3 py-[3px] px-5 rounded-lg flex items-center justify-center text-white text-[15px] bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 rounded-lg font-bold hover:text-gray-200"
                onClick={() => {
                  setGettingReceipt(true);
                  pickWinner();
                }}
              >
                Pick Winner
              </button>
              {/* )} */}
            </div>
            {gettingReceipt ? (
              <>
                <LoadingLogo className="h-[50px] w-[50px] animate-spin"></LoadingLogo>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center">
                {transactionSuccess != null && transactionSuccess === false && (
                  <>
                    <label className="z-20 text-[#d430b0] text-[16px] font-bold">
                      Transaction failed
                    </label>
                    <label className="z-20 text-[#d430b0] text-[16px] font-bold">
                      {errorMessage}
                    </label>
                  </>
                )}
                {transactionSuccess != null && transactionSuccess === true && (
                  <label className="z-20 text-[#26f5d8] text-[16px] font-bold ">
                    Transaction success
                  </label>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
