import React, { useState, useEffect, useContext, useCallback } from "react";
import { UseInfoUser } from "../web3/Web3Context";
import { FaunaDatabase, UseFaunaDatabase } from "../utils/Database";
import { ethers } from "ethers";
// this is useful for ensuring your errors are standardized
import {
  rpcErrors,
  providerErrors,
  serializeError,
} from "@metamask/rpc-errors";

import {
  REKT_HARDHAT,
  REKT_DODGE,
  REKT_DODGE_TEST,
  REKT_FTM,
  REKT_ETH,
  WNATIVE_HARDHAT,
  WNATIVE_FTM,
  WNATIVE_DODGE,
  WNATIVE_DODGE_TEST,
  WNATIVE_ETH,
  UNREKTUS_ADDRESS_HardHat,
  UNREKTUS_ADDRESS_FTM,
  UNREKTUS_ADDRESS_DODGE,
  UNREKTUS_ADDRESS_DODGE_TEST,
  UNREKTUS_ADDRESS_ETH,
  UNREKTUS_ABI,
} from "./config.js";
import key from "../utils/key";

import axios from "axios";
import { nativeSelectClasses } from "@mui/material";
const telegramToken = "7345565928:AAGuyn4tjBaQpEChZYueveGiSqzj6GmRdgE"; // Telegram UnrektusBot
const chatId = "-1001599215934"; // Unrektus Telegram chat ID

const chainNames = {
  "0x7a69": "HardHat",
  "0x7d0": "Dogechain",
  "0xfa": "Fantom",
  "0x1": "Ethereum",
};

export const InfoContract = React.createContext();
// hook
export function UseContractInfo() {
  return useContext(InfoContract);
}

export function ContractContext({ children }) {
  const {
    connected,
    currAddress,
    currChainId,
    currProvider,
    currSigner,
    wrongNetwork,
    comingSoon,
    changeDetected,
  } = UseInfoUser();
  const { sessionExists, addSessionData, updateSessionData } =
    UseFaunaDatabase();

  //   const [provider, setProvider] = useState(currProvider);
  //   const [signer, setSigner] = useState(currSigner);
  //   const [chain, setChainId] = useState(currChainId);

  const [unrektusContractRead, setUnrektusContractRead] = useState(null);
  const [unrektusContract, setUnrektusContract] = useState(null);
  const [initiated, setInitiated] = useState(false);
  const [tokenNative, setTokenNative] = useState("");
  const [currentStatus, setCurrentStatus] = useState(null);
  const [unrektusSession, setUnrektusSession] = useState(0);
  // const [sessionBreak, setSessionBreak] = useState(false);
  const [sessionDuration, setSessionDuration] = useState(0);
  const [sessionEnded, setSessionEnded] = useState(false);
  const [breakDuration, setBreakDuration] = useState(0);
  const [prizeValue, setPrizeValue] = useState(0);
  const [submissionFee, setSubmissionFee] = useState(0);
  const [boostFeePerX, setBoostFeePerX] = useState(0);
  const [bribeTax, setBribeTax] = useState(0);

  //   const [analyticsData, setAnalyticsData] = useState(null);
  const [isLoadingStatus, setIsLoadingStatus] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [submissionDetails, setSubmissionDetails] = useState(null);
  const [leaderboardData, setLeaderboardData] = useState(null);

  const [lastWinner, setLastWinner] = useState(null);
  const [winnerPicked, setWinnerPicked] = useState(false);

  //   useEffect(() => {
  //     if (provider !== currProvider) setProvider(currProvider);
  //     if (signer !== currSigner) setSigner(currSigner);
  //     if (chain !== currChainId) setChainId(currChainId);
  //   }, [currProvider, currSigner, currChainId]);

  const reset = async () => {
    setUnrektusContract(null);
    setUnrektusContractRead(null);
    setTokenNative("");
    setInitiated(false);
    setCurrentStatus(null);
    setUnrektusSession(0);
    // setSessionBreak(false);
    setSessionDuration(0);
    setSessionEnded(false);
    setBreakDuration(0);
    setPrizeValue(0);
  };
  /*
   * COntract creation Read only context
   */
  const createContractRead = async (provider, chain) => {
    if (provider && chain) {
      try {
        var contract;
        switch (chain) {
          case "0x7a69":
            contract = new ethers.Contract(
              UNREKTUS_ADDRESS_HardHat,
              UNREKTUS_ABI,
              provider
            );
            setUnrektusContractRead(contract);
            setTokenNative("ETH");
            break;
          case "0x7d0":
            contract = new ethers.Contract(
              UNREKTUS_ADDRESS_DODGE,
              UNREKTUS_ABI,
              provider
            );
            setUnrektusContractRead(contract);
            setTokenNative("DOGE");
            break;
          case "0x238": //dc test
            contract = new ethers.Contract(
              UNREKTUS_ADDRESS_DODGE_TEST,
              UNREKTUS_ABI,
              provider
            );
            setUnrektusContractRead(contract);
            setTokenNative("DOGE");
            break;
          case "0xfa":
            contract = new ethers.Contract(
              UNREKTUS_ADDRESS_FTM,
              UNREKTUS_ABI,
              provider
            );
            setUnrektusContractRead(contract);
            setTokenNative("FTM");
            break;
          case "0x1":
            contract = new ethers.Contract(
              UNREKTUS_ADDRESS_ETH,
              UNREKTUS_ABI,
              provider
            );
            setUnrektusContractRead(contract);
            setTokenNative("ETH");
            break;
          default:
            contract = null;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  /*
   * COntract creation based on chain (as address will be different)
   */
  const createContract = async (signer, chain) => {
    if (signer && chain) {
      try {
        var contract;
        switch (chain) {
          case "0x7a69":
            contract = new ethers.Contract(
              UNREKTUS_ADDRESS_HardHat,
              UNREKTUS_ABI,
              signer
            );
            setUnrektusContract(contract);
            setTokenNative("ETH");
            break;
          case "0x7d0":
            contract = new ethers.Contract(
              UNREKTUS_ADDRESS_DODGE,
              UNREKTUS_ABI,
              signer
            );
            setUnrektusContract(contract);
            setTokenNative("DOGE");
            break;
          case "0x238": //dc test
            contract = new ethers.Contract(
              UNREKTUS_ADDRESS_DODGE_TEST,
              UNREKTUS_ABI,
              signer
            );
            setUnrektusContract(contract);
            setTokenNative("DOGE");
            break;
          case "0xfa":
            contract = new ethers.Contract(
              UNREKTUS_ADDRESS_FTM,
              UNREKTUS_ABI,
              signer
            );
            setUnrektusContract(contract);
            setTokenNative("FTM");
            break;
          case "0x1":
            contract = new ethers.Contract(
              UNREKTUS_ADDRESS_ETH,
              UNREKTUS_ABI,
              signer
            );
            setUnrektusContract(contract);
            setTokenNative("ETH");
            break;
          default:
            contract = null;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  async function getTimeStamp() {
    if (currProvider) {
      // Get the block number
      const blockNumber = await currProvider.getBlockNumber();
      // getBlock returns a block object and it has a timestamp property.
      const block = await currProvider.getBlock(blockNumber);

      // Assign block.timestamp to timestamp variable
      const timestamp = block.timestamp;
      // console.log(timestamp);
      return timestamp;
    }
  }

  useEffect(() => {
    let isCurrent = true;
    let contract;

    if (isCurrent) {
      if (!wrongNetwork) {
        if (!comingSoon) {
          console.log(wrongNetwork, comingSoon);
          if (currSigner === null && currProvider === null) return;
          if (currSigner) {
            reset();
            console.log("Create Contract on :", currChainId);
            createContract(currSigner, currChainId);
          } else if (currProvider) {
            reset();
            console.log("Create Read Contract on :", currChainId);
            createContractRead(currProvider, currChainId);
          }
        }
      }
    }
    return () => {
      isCurrent = false;
    };
  }, [currProvider, currSigner]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      //   if (unrektusContract === null && unrektusContractRead === null) return;
      if (unrektusContract != null) {
        // console.log("Check status");
        // setIsLoadingStatus(true);
        setUnrektusContractRead(null);
        checkStatus(unrektusContract);
      } else if (unrektusContractRead != null) {
        checkStatus(unrektusContractRead);
      }
    }
    return () => {
      isCurrent = false;
    };
  }, [unrektusContract, unrektusContractRead]);
  /* ====================================
   * Database functions
   ==================================== */
  async function updateDatabaseSubmissionCount(totalSubmissions) {
    // console.log("Updated submissionCount", totalSubmissions);
    const exist = await sessionExists(`${unrektusSession}`);
    // console.log(exist);
    if (exist === true) {
      await updateSessionData(`${unrektusSession}`, {
        submissionCount: totalSubmissions,
      });
      // console.log("updated");
    } else {
      await addSessionData({
        sessionId: `${unrektusSession}`,
        submissionCount: totalSubmissions,
        votesCount: 0,
        bribesCount: 0,
      });
      // console.log("created");
    }
  }
  async function updateDatabaseVotesCount(totalVotes) {
    // console.log("Updated votesCount", totalVotes);
    // const exist = await sessionExists(`${unrektusSession}`);
    // if (exist === true) {
    updateSessionData(`${unrektusSession}`, { votesCount: totalVotes });
    // } else {
    //   addSessionData({
    //     sessionId: `${unrektusSession}`,
    //     votesCount: totalVotes,
    //   });
    // }
  }
  async function updateDatabaseBribeCount(totalBribes) {
    // console.log("Updated submissionCount", totalBribes);
    // const exist = await sessionExists(`${unrektusSession}`);
    // if (exist === true) {
    updateSessionData(`${unrektusSession}`, {
      bribesCount: totalBribes,
    });
    // } else {
    //   addSessionData({
    //     sessionId: unrektusSession,
    //     bribesCount: totalBribes,
    //   });
    // }
  }
  /* ====================================
   * Contract Read Status
   ==================================== */
  async function checkStatus(contract) {
    if (contract) {
      setIsLoadingStatus(true);
      try {
        const status = await contract.checkStatus();
        console.log(status);
        if (status) {
          // console.log(status.currentSessionId);
          // console.log(status._currentWiningPot);
          setUnrektusSession(Number(status.currentSessionId));
          // setSessionBreak(status.inBreak);
          const sessionDuration = status._sessionDurationInHours;
          // const breakDuration = status._sessionBreakInDays;
          const time = status._latestSessionIdIncreasedTime;
          const timestamp = Number(await getTimeStamp());
          // console.log(Number(time), Number(timestamp));
          const sessionTimeLeft =
            Number(time) + Number(sessionDuration) * 3600 - timestamp;
          // const breakTimeLeft =
          //   Number(time) +
          //   (Number(sessionDuration) * 86400 + Number(breakDuration) * 86400) -
          //   timestamp;
          // console.log(Number(time), Number(timestamp));
          // console.log(Number(sessionTimeLeft), Number(breakTimeLeft));
          // console.log(sessionTimeLeft);

          if (Number(sessionTimeLeft) < 0) {
            setSessionEnded(true);

            // if (Number(breakTimeLeft) > 0) {
            //   setSessionBreak(true);
            //   setBreakDuration(Number(breakTimeLeft));
            // } else {
            //   setSessionBreak(false);
            // }
          } else {
            setSessionEnded(false);
            setSessionDuration(Number(sessionTimeLeft));
          }
          // console.log(ethers.formatEther(status._currentWiningPot));
          setPrizeValue(Number(ethers.formatEther(status._currentWiningPot)));
          // console.log(tokenNative);

          const feeInfo = await contract.getFeesInfo();

          const bribeTax = Number(feeInfo._bribeTaxPercent);
          const submissionFee =
            Number(ethers.formatEther(status._currentWiningPot)) /
            Number(feeInfo._submissionFeeDivider);
          console.log(submissionFee);

          const boostFee =
            Number(ethers.formatEther(status._currentWiningPot)) /
            Number(feeInfo._boostFeeDivider);
          // console.log(boostFee);

          setSubmissionFee(submissionFee);
          setBoostFeePerX(boostFee);
          setBribeTax(bribeTax);

          // console.log(submissionFeeInRekt);
          // console.log("Current Status ", status);
          setCurrentStatus(status);
          setInitiated(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  const getLastWinnerInfo = async () => {
    if (currentStatus) {
      var winners = Number(currentStatus.totalWinners);

      if (winners > 0) {
        const lastWinner = await getWinnerInfo(winners - 1);
        // console.log(lastWinner);
        if (lastWinner != null) {
          // console.log(
          //   sessionEnded,
          //   sessionBreak,
          //   Number(lastWinner.sessionId),
          //   Number(lastWinner.submissionId)
          // );
          setLastWinner(lastWinner);
          if (lastWinner.sessionId === unrektusSession) {
            setWinnerPicked(true);
          }
        }
      }
      // } else if (unrektusSession === 1) {
      //   // setWinnerPicked(true);
      // }
      return lastWinner;
    }
  };

  useEffect(() => {
    if (currentStatus) {
      setIsLoadingStatus(false);
      if (unrektusContract) {
        const getWinner = async () => {
          console.log("status changed, fetching new data");
          await getLastWinnerInfo();
          await getSubmissionPropertiesAll();
          setIsLoadingData(false);
        };
        getWinner();
      }
    }
  }, [currentStatus]);
  /* ====================================
   * Contract Read Submissions
   ==================================== */

  async function getSubmissionPropertiesById(submissionId) {
    // console.log(submissionId);
    const submission = await unrektusContract.submissionInfo(submissionId); //struct indexed on submissionID
    // console.log(array);
    var submissionInfo = {
      sessionId: Number(submission.sessionId), //0 sessionId{ txOwner: array.txOwner }); //1 owner
      txOwner: submission.txOwner,
      amountRekt: Number(submission.amountRekt), //2 owner
      txHash: submission.txHash,
      buyTxn: submission.buyTx, //3
      sellTxn: submission.sellTx, //4
      totalBribe: Number(ethers.formatEther(submission.totalBribe)), //5
      totalVotes: Number(ethers.formatEther(submission.boostedVoteReceived)), //6
      boostX: Number(submission.boostX),
      submissionId: Number(submissionId), //7
    };
    return submissionInfo;
  }

  /* Contract context function (Read)
   * Returns return array of all Submissions with their info
   */
  const getSubmissionPropertiesAll = async () => {
    // console.log("getSubmissionPropertiesAll");

    const totalSubmissions = Number(currentStatus._totalCurrentSubmissions);
    // console.log("Total submissions", totalSubmissions);
    // console.log(result);
    var submissionArray = [];
    if (totalSubmissions > 0) {
      //   updateDatabaseSubmissionCount(totalSubmissions);
      var voteLeader = [];
      var votesCount = 0;
      var bribesCount = 0;
      try {
        for (var i = 0; i < totalSubmissions; i++) {
          var submissionId = await unrektusContract.totalCurrentSubmissions(i);
          // console.log(submissionId);

          var submissionInfo = await getSubmissionPropertiesById(submissionId);
          submissionArray.push(submissionInfo);

          if (submissionInfo.totalVotes > 0) {
            voteLeader.push(submissionInfo);
            votesCount += submissionInfo.totalVotes;
          }
          if (submissionInfo.totalBribe > 0) {
            bribesCount += submissionInfo.totalBribe;
          }
          // console.log(submissionInfo);
        }
        setLeaderboardData(voteLeader);
        setSubmissionDetails(submissionArray);
        // if (votesCount > 0) updateDatabaseVotesCount(votesCount);
        // if (bribesCount > 0) updateDatabaseBribeCount(bribesCount);
      } catch (error) {
        console.log(error);
      }
    }
    return submissionArray;
  };

  /* ====================================
   * Contract Read Winners
   ==================================== */
  async function getWinnerInfo(index) {
    const winner = await unrektusContract.winnerList(index); //public array
    // console.log(winner); //buy TRXN
    const array = await unrektusContract.winnerInfo(winner); //struct indexed on buy Trnx
    // console.log(array);
    const submissionId = array.submissionId;
    var winnerInfo = await getSubmissionPropertiesById(submissionId);
    return winnerInfo;
  }
  async function getAllWinners(totalWinners) {
    // console.log("getAllWinners ", totalWinners);
    var winnerArray = [];
    if (totalWinners > 0) {
      for (var i = 0; i < totalWinners; i++) {
        var winnerInfo = await getWinnerInfo(i);
        winnerArray.push(winnerInfo);
      }
    }
    return winnerArray;
  }
  /* ====================================
   * Contract Read user info
   ==================================== */

  /* return latestSubmissionId and allSubmissions ID */
  async function getUserSubmission() {
    try {
      const result = await unrektusContract.getUserSubmission(currAddress);
      // console.log(result);
      const currentSubmissionCounts = Number(result.currentSubmissionCounts);
      const userAllSubmissions = result.allSubmissions;
      // console.log(userAllSubmissions);
      return { currentSubmissionCounts, userAllSubmissions };
    } catch (error) {
      console.log(error);
    }
  }

  async function getUserSubmissionsDetails(userAllSubmissions) {
    // console.log("getSubmissionDetails");
    // console.log(userAllSubmissions);
    var won = false;
    var wonSubmissions = [];
    var currentSubmissionInfo = [];
    var pastSubmissionInfo = [];

    if (userAllSubmissions.length > 0) {
      for (var i = 0; i < userAllSubmissions.length; i++) {
        const submissionId = userAllSubmissions[i];
        const submission = await unrektusContract.submissionInfo(submissionId);
        // console.log(submission);
        const submitterInfo = await unrektusContract.showSubmitterInfo(
          currAddress,
          submissionId
        );
        // console.log(submitterInfo);
        var submissionInfo = {
          sessionId: Number(submission.sessionId), //0 sessionId{ txOwner: array.txOwner }); //1 owner
          txOwner: submission.txOwner,
          amountRekt: Number(submission.amountRekt), //2 owner
          txHash: submission.txHash,
          buyTxn: submission.buyTx, //3
          sellTxn: submission.sellTx, //4
          totalBribe: Number(ethers.formatEther(submission.totalBribe)), //5
          totalVotes: Number(
            ethers.formatEther(submission.boostedVoteReceived)
          ), //6
          boostX: Number(submission.boostX),
          submissionId: Number(submissionId), //7
          bribeSet: submitterInfo.bribeSet,
          bribeWithdrew: submitterInfo.bribeWithdrew,
        };
        //current session?
        if (Number(submission.sessionId) === unrektusSession) {
          currentSubmissionInfo.push(submissionInfo);
        }
        pastSubmissionInfo.push(submissionInfo);

        //check past wins
        var winnerInfo = await unrektusContract.winnerInfo(submission.txHash);
        // console.log(winnerInfo);
        if (winnerInfo.won === true) {
          // console.log("won");
          won = true;

          var winInfo = {
            sessionId: Number(submission.sessionId), //0 sessionId{ txOwner: array.txOwner }); //1 owner
            // amountRekt: Number(submissionInfo.amountRekt), //2 owner
            txHash: submission.txHash,
            buyTxn: submission.buyTx, //3
            sellTxn: submission.sellTx, //4
            // totalBribe: Number(submissionInfo.totalBribe), //5
            // totalVotes: Number(submissionInfo.voteReceived), //6
            submissionId: Number(submissionId), //7
            totalPotWon: Number(ethers.formatEther(winnerInfo.totalWon)),
          };
          wonSubmissions.push(winInfo);
        }
      }
    }
    return { currentSubmissionInfo, won, wonSubmissions, pastSubmissionInfo };
  }
  /* Contract context function (Read)
   * Returns alreadySubmitted(bool), currentSubmissionID, pastWins
   */
  const checkUserSubmissionInfo = async () => {
    if (unrektusContract) {
      try {
        // console.log("checkUserSubmissionInfo");
        const { currentSubmissionCounts, userAllSubmissions } =
          await getUserSubmission();
        // console.log(Number(currentSubmissionCounts));
        //check already submited
        var alreadySubmitted = false;
        if (currentSubmissionCounts > 0) {
          alreadySubmitted = true;
        }
        // console.log(userAllSubmissions); //all submissionID array

        const {
          currentSubmissionInfo,
          won,
          wonSubmissions,
          pastSubmissionInfo,
        } = await getUserSubmissionsDetails(userAllSubmissions);

        return {
          alreadySubmitted,
          currentSubmissionCounts,
          currentSubmissionInfo,
          won,
          wonSubmissions,
          pastSubmissionInfo,
        };
      } catch (error) {
        console.log(error);
        return null;
      }
    } else return null;
  };

  async function getUserVotes() {
    try {
      const result = await unrektusContract.showVoterInfo(currAddress);
      const activeVotes = result.activeVotes;
      const votingHistory = result.votingHistory;
      // console.log(allSubmissions);
      return { activeVotes, votingHistory };
    } catch (error) {
      console.log(error);
    }
  }
  async function getUserVotesDetails(votingHistory) {
    var userVotesDetails = [];

    if (votingHistory.length > 0) {
      for (var i = 0; i < votingHistory.length; i++) {
        const submissionId = Number(votingHistory[i]);
        const vote = await unrektusContract.showVoteInfo(
          currAddress,
          submissionId
        );

        var rektVoted = Number(ethers.formatEther(vote.totalRektVoted));
        // console.log("rektVoted", rektVoted);
        var userBribeClaim = await getEligibleEarnings(submissionId, rektVoted);

        var voteInfo = {
          submissionId: submissionId,
          sessionId: Number(vote._sessionId),
          totalRektVoted: rektVoted,
          bribeClaimed: vote.bribeClaimed, //bool
          eligibleEarning: userBribeClaim,
          rektUnstaked: vote.rektUnstaked, //bool
        };
        // console.log(voteInfo);
        userVotesDetails.push(voteInfo);
      }
    }
    return userVotesDetails;
  }

  const checkUserVotesInfo = async () => {
    if (unrektusContract) {
      try {
        const { activeVotes, votingHistory } = await getUserVotes();

        const userCurrentVotes = await getUserVotesDetails(activeVotes);
        const userPastVotes = await getUserVotesDetails(votingHistory);

        return { userCurrentVotes, userPastVotes };
      } catch (error) {
        console.log(error);
        return null;
      }
    } else return null;
  };

  async function getEligibleEarnings(submissionId, totalRektVotes) {
    const submission = await unrektusContract.submissionInfo(submissionId);
    const totalBribe = Number(ethers.formatEther(submission.totalBribe));
    const totalVotes = Number(
      ethers.formatEther(submission.actualVoteReceived)
    );
    // console.log("totalVotes", totalVotes);
    const voterVotePercentage = (totalRektVotes * 100) / totalVotes;
    const eligibleBribe = (totalBribe * voterVotePercentage) / 100;
    return eligibleBribe;
  }
  /* ====================================
   * Contract token Approve
   ==================================== */
  /* REKT Token approve for voting */
  async function tokenApprove(currency, value) {
    let abiERC20 = [
      "function approve(address, uint256) external returns(bool)",
      "function allowance(address, address) external view returns(uint256)",
      "function balanceOf(address) external view returns(uint256)",
    ];
    var tokenAddress;
    switch (currency) {
      case "REKT_HARDHAT":
        tokenAddress = REKT_HARDHAT;
        break;
      case "REKT_DODGE":
        tokenAddress = REKT_DODGE;
        break;
      case "REKT_DODGE_TEST":
        tokenAddress = REKT_DODGE_TEST;
        break;
      case "REKT_FTM":
        tokenAddress = REKT_FTM;
        break;
      case "REKT_ETH":
        tokenAddress = REKT_ETH;
        break;
      case "WNATIVE_HARDHAT":
        tokenAddress = WNATIVE_HARDHAT;
        break;
      case "WNATIVE_DODGE":
        tokenAddress = WNATIVE_DODGE;
        break;
      case "WNATIVE_DODGE_TEST":
        tokenAddress = WNATIVE_DODGE_TEST;
        break;
      case "WNATIVE_FTM":
        tokenAddress = WNATIVE_FTM;
        break;
      case "WNATIVE_ETH":
        tokenAddress = WNATIVE_ETH;
        break;
      default:
        tokenAddress = "";
    }
    // console.log("tokenAddress", tokenAddress);
    const tokenContract = new ethers.Contract(
      tokenAddress,
      abiERC20,
      currSigner
    );
    try {
      let balance = await tokenContract.balanceOf(currAddress);
      // console.log(
      //   "Approve? Balance of ",
      //   currency,
      //   " ",
      //   ethers.formatEther(balance)
      // );
      let successToken;
      let messageToken = "";

      if (Number(ethers.formatEther(balance)) >= value) {
        let contractAddress = "";

        switch (currChainId) {
          case "0x7a69": //hardhat
            contractAddress = UNREKTUS_ADDRESS_HardHat;
            break;
          case "0x7d0": //dc
            contractAddress = UNREKTUS_ADDRESS_DODGE;
            break;
          case "0x238": //dc test
            contractAddress = UNREKTUS_ADDRESS_DODGE_TEST;
            break;
          case "0xfa": //ftm
            contractAddress = UNREKTUS_ADDRESS_FTM;
            break;
          case "0x1": //eth
            contractAddress = UNREKTUS_ADDRESS_ETH;
            break;
          default:
            contractAddress = "";
        }
        // console.log(contractAddress);
        let allowance = await tokenContract.allowance(
          currAddress,
          contractAddress
        );
        console.log("Allowance", ethers.formatEther(allowance));
        console.log("Value", value);

        if (Number(ethers.formatEther(allowance)) < value + 1) {
          const approved = await tokenContract.approve(
            contractAddress,
            ethers.parseUnits((value + 1).toString(), "ether")
          );
          const receipt = await approved.wait();
          // console.log(receipt);
          if (receipt) {
            successToken = true;
            messageToken = "Token Approve Success";
          } else {
            successToken = false;
            messageToken = "Token Approve Failed";
          }
        } else {
          successToken = true;
          messageToken = "Allowance already set";
        }
        // else return true;
      } else {
        successToken = false;
        messageToken = "Not enough balance";
      }
      return { success: successToken, message: messageToken };
    } catch (error) {
      let success = false;
      let message = "Error in Approve token";
      console.log("Error in Approve token\n" + error);
      return { success, message };
    }
  }
  /* ====================================
   * Contract write Submissions functions
   ==================================== */
  async function queryEvent(eventFilter, blockNumber) {
    // const query = async (interval) => {
    const events = await unrektusContract.queryFilter(eventFilter, blockNumber);
    // console.log(events);
    if (events.length > 0) {
      const hash = events[0].args[0];
      if (hash) {
        console.log("Event Success");
        // clearInterval(interval);
        return true;
      }
    }
    // };
    // const interval = setInterval(function () {
    //   // setTimeout(() => {
    //   console.log("Waiting for event receipt...");
    //   const getQuery = async () => {
    //     await query(interval);
    //   };
    //   getQuery();
    // }, "2000");
  }

  function createTxHash(buyTxn, sellTxn) {
    try {
      let hash = ethers.solidityPackedKeccak256(
        ["bytes32", "bytes32"],
        [buyTxn, sellTxn]
      );
      console.log("Trxn hash", hash);
      return hash;
    } catch (error) {
      console.log(error);
    }
  }
  async function sendTrxnRektMaster(
    txHash,
    buyTxn,
    sellTxn,
    amountRektInNative
  ) {
    //handle submit only by rekt master
    let success = false;
    let message = "";
    try {
      var rektMasterSigner;
      // console.log(rektMasterSigner);
      let unrektus_Add = "";

      // console.log(chain);
      switch (currChainId) {
        case "0x7a69": //harhat
          unrektus_Add = UNREKTUS_ADDRESS_HardHat;
          rektMasterSigner = new ethers.Wallet(
            "0xac0974bec39a17e36ba4a6b4d238ff944bacb478cbed5efcae784d7bf4f2ff80",
            // process.env.REACT_APP_DEPLOYER_KEY,
            currProvider
          );
          break;
        case "0x7d0": //dc
          unrektus_Add = UNREKTUS_ADDRESS_DODGE;
          const value = await key.getRektKey();
          rektMasterSigner = new ethers.Wallet(value, currProvider);
          break;
        case "0x238": //dc test
          unrektus_Add = UNREKTUS_ADDRESS_DODGE_TEST;
          const value1 = await key.getRektKey();
          rektMasterSigner = new ethers.Wallet(value1, currProvider);
          break;
        case "0xfa": //ftm
          unrektus_Add = UNREKTUS_ADDRESS_FTM;
          const value2 = await key.getRektKey();
          rektMasterSigner = new ethers.Wallet(value2, currProvider);
          break;
        case "0x1": //eth
          unrektus_Add = UNREKTUS_ADDRESS_ETH;
          const value3 = await key.getRektKey();
          rektMasterSigner = new ethers.Wallet(value3, currProvider);
          break;
        default:
          unrektus_Add = "";
      }
      const contractInstance = new ethers.Contract(
        unrektus_Add,
        UNREKTUS_ABI,
        rektMasterSigner
      );
      //   console.log(unrektus_Add);
      //   console.log(contractInstance);
      // const gasPrice = await provider.send("eth_feeHistory", [
      //   "0x5",
      //   "latest",
      //   [],
      // ]);
      //   console.log(txHash, buyTxn, sellTxn, currAddress, amountRektInNative);
      const estimatedGas = await contractInstance.addTxs.estimateGas(
        txHash,
        buyTxn,
        sellTxn,
        currAddress,
        amountRektInNative,
        { from: rektMasterSigner.getAddress() }
      );
      //   console.log(Number(estimatedGas));

      const overrides = {
        from: rektMasterSigner.getAddress(),
        gasLimit: estimatedGas * 2n,
      };
      const trxn = await contractInstance.addTxs(
        txHash,
        buyTxn,
        sellTxn,
        currAddress,
        amountRektInNative,
        overrides
      );

      const receipt = await trxn.wait();
      if (receipt) {
        console.log("Trxn Success");
        success = receipt;
        message = "Submit Success";
      }
      return { success, message };
      // }
    } catch (error) {
      console.log(error);
      if (error.reason) {
        message = error.reason;
      }
      return { success, message };
    }
  }
  /* Contract context function (Write)
   * Returns bool
   */
  const handleSubmit = async (buyTxn, sellTxn, amountRektInNative) => {
    if (!sessionEnded) {
      if (unrektusContract) {
        let status;

        let receipt;
        // console.log("submission fee", submissionFee);
        switch (currChainId) {
          case "0x7a69": //hardhat
            receipt = await tokenApprove("WNATIVE_HARDHAT", submissionFee);
            break;
          case "0x7d0": //dc
            receipt = await tokenApprove("WNATIVE_DODGE", submissionFee);
            break;
          case "0x238": //dc test
            receipt = await tokenApprove("WNATIVE_DODGE_TEST", submissionFee);
            break;
          case "0xfa": //ftm
            receipt = await tokenApprove("WNATIVE_FTM", submissionFee);
            break;
          case "0x1": //eth
            receipt = await tokenApprove("WNATIVE_ETH", submissionFee);
            break;
          default:
            receipt = null;
        }
        // console.log(receipt);
        if (receipt.success) {
          console.log("Approve success");
          const txHash = createTxHash(buyTxn, sellTxn);
          // console.log(txHash);
          const { success, message } = await sendTrxnRektMaster(
            txHash,
            buyTxn,
            sellTxn,
            amountRektInNative
          );
          if (success) {
            let msgTG = "";
            msgTG +=
              "Unrektus " +
              chainNames[currChainId] +
              " Alert: New Submission! \n" +
              currAddress +
              "\n" +
              "Got REKT by " +
              amountRektInNative +
              " " +
              tokenNative +
              " \n";
            msgTG += "Let's get UNREKT! \n";
            msgTG += "unrektus.fi/vote \n";
            sendMessageToTelegramGroup(telegramToken, chatId, msgTG);
            // console.log(success);
            const eventFilter = await unrektusContract.filters.TXsAdded();
            const eventFired = await queryEvent(
              eventFilter,
              success.blockNumber
            );
            if (eventFired) {
              status = true;
              return { status, message };
            } else {
              status = false;
              return { status, message };
            }
          } else {
            status = false;
            return { status, message };
          }
        } else {
          status = false;
          let message = receipt.message;
          return { status, message };
        }
      }
    }
  };

  /* ====================================
   * Contract write vote functions
   ==================================== */

  async function vote(submissionId, totalRektForVote) {
    // const sendAmt = totalRektForVote;
    let success;
    let message = "";
    try {
      const overrides = { from: currAddress };
      const trxn = await unrektusContract.vote(
        submissionId,
        ethers.parseUnits(totalRektForVote, "ether"),
        overrides
      );

      // console.log(trxn);
      const receipt = await trxn.wait();
      //console.log(receipt);
      if (receipt) {
        console.log("Trxn Success");
        success = receipt;
        message = "Vote Success";

        let msgTG = "";
        msgTG +=
          "Unrektus " +
          chainNames[currChainId] +
          " Alert: New Vote! \n" +
          currAddress +
          "\n" +
          "Voted for submission " +
          submissionId +
          " \n";
        msgTG += "Using " + totalRektForVote + " uREKT \n\n";
        msgTG += "Let's get UNREKT! \n";
        msgTG += "unrektus.fi/leaderboard \n";
        sendMessageToTelegramGroup(telegramToken, chatId, msgTG);
      }
      return { success, message };
    } catch (error) {
      console.log(error);
      if (error.reason) {
        message = error.reason;
      }
      return { success, message };
    }
  }

  /* Contract context function (Write)
   * Returns bool
   */
  const handleVote = async (voteData) => {
    // console.log("handleVote");
    // console.log(voteData.voteAmount, voteData.submissionId);
    if (!sessionEnded) {
      if (unrektusContract) {
        var receipt;
        let status;

        switch (currChainId) {
          case "0x7a69": //harhat
            receipt = await tokenApprove("REKT_HARDHAT", voteData.voteAmount);
            break;
          case "0x7d0": //dc
            receipt = await tokenApprove("REKT_DODGE", voteData.voteAmount);
            break;
          case "0x238": //dc test
            receipt = await tokenApprove(
              "REKT_DODGE_TEST",
              voteData.voteAmount
            );
            break;
          case "0xfa": //ftm
            receipt = await tokenApprove("REKT_FTM", voteData.voteAmount);
            break;
          case "0x1": //eth
            receipt = await tokenApprove("REKT_ETH", voteData.voteAmount);
            break;
          default:
            receipt = null;
        }

        if (receipt.success) {
          console.log("Approve success");
          const { success, message } = await vote(
            voteData.submissionId,
            voteData.voteAmount
          );
          if (success) {
            const eventFilter = await unrektusContract.filters.Voted();
            const eventFired = await queryEvent(
              eventFilter,
              success.blockNumber
            );
            if (eventFired) {
              status = true;
              return { status, message };
            } else {
              status = false;
              return { status, message };
            }
          } else {
            status = false;
            return { status, message };
          }
        } else {
          status = false;
          let message = receipt.message;
          return { status, message };
        }
      }
    }
  };

  /* ====================================
   * Contract write Bribe functions
   ==================================== */

  async function setBribe(bribeAmountInNative, submissionId) {
    let success;
    let message = "";
    try {
      // const sendAmt = ethers.BigNumber.from(bribeAmountInNative);

      const balance = await currProvider.getBalance(currAddress);

      if (ethers.formatEther(balance) >= Number(bribeAmountInNative)) {
        const overrides = {
          from: currAddress,
          value: ethers.parseUnits(bribeAmountInNative, "ether"),
        };
        const trxn = await unrektusContract.setBribe(submissionId, overrides);
        // console.log(trxn);
        const receipt = await trxn.wait();
        //console.log(receipt);
        if (receipt) {
          console.log("Trxn Success");
          success = receipt;
          message = "Add Bribe Success";
          const currentSubmissionInfo = await checkUserSubmissionInfo();
          // console.log(currentSubmissionInfo.currentSubmissionInfo[0]);
          let totalNewBribe =
            currentSubmissionInfo.currentSubmissionInfo[0].totalBribe;
          // console.log(totalNewBribe);
          let bribeAmount = bribeAmountInNative * (1 - bribeTax / 100);
          let msgTG = "";
          msgTG +=
            "Unrektus " +
            chainNames[currChainId] +
            " Alert: New Bribe! \n" +
            currAddress +
            "\n" +
            "Added a bribe of " +
            bribeAmount +
            " " +
            tokenNative +
            " \n";
          msgTG += "On submission " + submissionId + " \n\n";
          msgTG +=
            "New total bribe: " + totalNewBribe + " " + tokenNative + " \n";
          msgTG += "Vote on that submission to get its bribe! \n";
          msgTG += "unrektus.fi/vote \n";
          sendMessageToTelegramGroup(telegramToken, chatId, msgTG);
          // checkBribeAddedEvent(trxn);
        }
      } else {
        message = "Not enough balance";
      }
      return { success, message };
    } catch (error) {
      console.log(error);
      if (error.reason) {
        message = error.reason;
      } else {
        // you can add a custom fallback error code and message if desired
        // const fallbackError = { code: 4999, message: "My custom error." };
        const err = serializeError(error);
        message = err.message;
      }
      return { success, message };
    }
  }
  /* Contract context function (Write)
   * Returns bool
   */
  const handleBribe = async (bribeData) => {
    // console.log("handleBribe");
    // console.log(bribeData.bribeAmount, bribeData.submissionId);

    if (!sessionEnded) {
      if (unrektusContract) {
        // const approveTrxn = await tokenApprove("USDC", bribeData.bribeAmount);
        // const rec = await approveTrxn.wait();
        // if (rec) {
        // console.log("Approve success");
        let status = false;

        const { success, message } = await setBribe(
          bribeData.bribeAmount,
          bribeData.submissionId
        );
        if (success) {
          const eventFilter = unrektusContract.filters.BribeAdded();
          const eventFired = await queryEvent(eventFilter, success.blockNumber);
          if (eventFired) {
            status = true;
            return { status, message };
          } else {
            return { status, message };
          }
        } else {
          return { status, message };
        }
      }
    }
  };

  async function setBoost(submissionId, boostMultiplier) {
    let success;
    let message = "";
    try {
      const sendAmt = boostFeePerX * (boostMultiplier - 1);
      const balance = await currProvider.getBalance(currAddress);

      if (ethers.formatEther(balance) >= sendAmt) {
        const overrides = {
          from: currAddress,
          value: ethers.parseUnits(`${sendAmt}`, "ether"), //for 2x charge 1
        };

        const trxn = await unrektusContract.addBoost(
          submissionId,
          boostMultiplier,
          overrides
        );
        console.log(trxn);
        const receipt = await trxn.wait();
        console.log(receipt);
        if (receipt) {
          console.log("Trxn Success");
          success = receipt;

          let msgTG = "";
          msgTG +=
            "Unrektus " +
            chainNames[currChainId] +
            " Alert: New Boost \n" +
            currAddress +
            "\n" +
            "has boosted submission " +
            submissionId +
            " by " +
            boostMultiplier +
            "x" +
            "\n";
          msgTG += "unrektus.fi/vote \n";
          sendMessageToTelegramGroup(telegramToken, chatId, msgTG);
        }
      } else {
        message = "Not enough balance";
      }
      return { success, message };
    } catch (error) {
      console.log(error);
      if (error.reason) {
        message = error.reason;
      } else {
        // you can add a custom fallback error code and message if desired
        // const fallbackError = { code: 4999, message: "My custom error." };
        const err = serializeError(error);
        message = err.message;
      }
      return { success, message };
    }
  }
  const handleBoost = async (boostData) => {
    // console.log("handleBoost");
    // console.log(boostData.boostX, boostData.submissionId);

    if (!sessionEnded) {
      if (unrektusContract) {
        // const approveTrxn = await tokenApprove("USDC", bribeData.bribeAmount);
        // const rec = await approveTrxn.wait();
        // if (rec) {
        // console.log("Approve success");
        let status;

        const { success, message } = await setBoost(
          boostData.submissionId,
          boostData.boostX
        );
        if (success) {
          const eventFilter = unrektusContract.filters.BoostAdded();
          const eventFired = await queryEvent(eventFilter, success.blockNumber);
          if (eventFired) {
            status = true;
            return { status, message };
          } else {
            status = false;
            return { status, message };
          }
        } else {
          status = false;
          return { status, message };
        }
      }
    }
  };
  async function withdrawBribe(submissionId) {
    let success;
    let message = "";
    try {
      const overrides = { from: currAddress };
      const trxn = await unrektusContract.withDrawBribe(
        submissionId,
        overrides
      );
      // console.log(trxn);
      const receipt = await trxn.wait();
      //console.log(receipt);
      if (receipt) {
        console.log("Trxn Success");
        success = receipt;
        message = "Withdraw Bribe Success";
      }
      return { success, message };
    } catch (error) {
      console.log(error);
      if (error.reason) {
        message = error.reason;
      }
      return { success, message };
    }
  }

  /* Contract context function (Write)
   * Returns bool
   */
  const handleWithdrawBribe = async (bribeData) => {
    // console.log("handleWithdrawBribe");
    // console.log(bribeData.submissionId);

    // if (sessionEnded) {
    if (unrektusContract) {
      // const approveTrxn = await tokenApprove("USDC", bribeData.bribeAmount);
      // const rec = await approveTrxn.wait();
      // if (rec) {
      // console.log("Approve success");
      let status;
      const { success, message } = await withdrawBribe(bribeData.submissionId);
      if (success) {
        status = true;
        return { status, message };
      } else {
        status = false;
        return { status, message };
      }
      // }
    }
    // }
  };
  async function claimBribe(submissionId) {
    let success;
    let message = "";
    try {
      const overrides = { from: currAddress };
      const trxn = await unrektusContract.claimBribe(submissionId, overrides);
      // console.log(trxn);
      const receipt = await trxn.wait();
      //console.log(receipt);
      if (receipt) {
        console.log("Trxn Success");
        success = receipt;
        message = "Claim Bribe Success";
      }
      return { success, message };
    } catch (error) {
      console.log(error);
      if (error.reason) {
        message = error.reason;
      }
      return { success, message };
    }
  }
  /* Contract context function (Write)
   * Returns bool
   */
  const handleClaimBribe = async (claimArray) => {
    // console.log("handleClaimBribe");
    // console.log(claimArray.submissionId);
    let status;
    // if (sessionEnded) {
    if (unrektusContract) {
      const { success, message } = await claimBribe(claimArray.submissionId);
      if (success) {
        status = true;
        return { status, message };
      } else {
        status = false;
        return { status, message };
      }
    }
    // }
  };

  function sendMessageToTelegramGroup(token, chatId, message) {
    const url = `https://api.telegram.org/bot${token}/sendMessage`;
    const data = {
      chat_id: chatId,
      text: message,
    };

    axios
      .post(url, data)
      .then((response) => {
        console.log("Message envoyé avec succès");
      })
      .catch((error) => {
        console.error("Erreur lors de l'envoi du message", error);
      });
  }

  async function pickWinner() {
    let success;
    let message = "";
    try {
      const overrides = { from: currAddress };
      const trxn = await unrektusContract.pickWinner(overrides);
      // console.log(trxn);
      const receipt = await trxn.wait();
      // console.log(receipt);
      if (receipt) {
        console.log("Trxn Success");
        success = receipt;
      }
      return { success, message };
    } catch (error) {
      console.log(error);
      if (error.reason) {
        message = error.reason;
      }
      return { success, message };
    }
  }
  const handlePickWinner = async () => {
    // console.log("handlePickWinner");
    // console.log(sessionEnded);
    let status;
    if (sessionEnded) {
      const { success, message } = await pickWinner();
      // console.log(status);

      if (success) {
        const eventFilter = unrektusContract.filters.WinnerPicked();
        console.log(eventFilter);
        const eventFired = await queryEvent(eventFilter, success.blockNumber);
        console.log(eventFired);
        if (eventFired) {
          // await checkStatus(unrektusContract);
          // console.log(currentStatus);
          // console.log("newStatus gotten");
          let newWinner = await getLastWinnerInfo();
          console.log("newWinner");
          console.log(newWinner);
          console.log("newWinner.txOwners");
          console.log(newWinner.txOwners);
          let message = "";
          message += "Winner picked! \n";
          message +=
            newWinner.txOwner +
            " \n" +
            "Won: " +
            Number(ethers.formatEther(currentStatus._currentWiningPot)).toFixed(
              2
            ) +
            " " +
            tokenNative +
            "\n";
          message +=
            "Bribed: " + newWinner.totalBribe + " " + tokenNative + "\n";
          message += "Received: " + newWinner.totalVotes + " Votes" + "\n\n";
          message +=
            "Session " +
            toString(Number(unrektusSession + 1)) +
            " has started: \n";
          message +=
            " - Winning prize: " +
            ((prizeValue * 29) / 30).toFixed(2) +
            " " +
            tokenNative +
            "\n";
          message += " - End of new session in: " + 3 + " days \n";
          message += "Let's get UNREKT! \n";
          message += "Only at unrektus.fi \n";
          sendMessageToTelegramGroup(telegramToken, chatId, message);
          status = true;
          return { status, message };
        } else {
          return { status, message };
        }
      } else {
        return { status, message };
      }
    }
  };

  async function increaseSessionId() {
    let success;
    let message = "";
    try {
      const overrides = { from: currAddress };
      const trxn = await unrektusContract.increaseSessionId(overrides);
      // console.log(trxn);
      const receipt = await trxn.wait();
      // console.log(receipt);
      if (receipt) {
        console.log("Trxn Success");
        success = receipt;
        message = "Increase Session Success";
      }
      return { success, message };
    } catch (error) {
      console.log(error);
      if (error.reason) {
        message = error.reason;
      }
      return { success, message };
    }
  }
  const handleIncreaseSession = async () => {
    // console.log("handleIncreaseSession");
    // console.log(sessionEnded);
    let status;
    if (initiated && currentStatus) {
      const { success, message } = await increaseSessionId();
      // console.log(status);
      if (success) {
        status = true;
        return { status, message };
      } else {
        return { status, message };
      }
    }
  };

  async function sendWinPot(potValue) {
    let success;
    let message = "";
    try {
      const balance = await currProvider.getBalance(currAddress);

      if (ethers.formatEther(balance) >= Number(potValue)) {
        const overrides = {
          from: currAddress,
          value: ethers.parseEther(potValue.toString()),
        };
        const trxn = await unrektusContract.sendWinPot(overrides);
        // console.log(trxn);
        const receipt = await trxn.wait();
        // console.log(receipt);
        if (receipt) {
          console.log("Trxn Success");
          success = receipt;
          message = "Send Pot Success";
        }
      } else {
        message = "Not enough balance";
      }
      return { success, message };
    } catch (error) {
      console.log(error);
      if (error.reason) {
        message = error.reason;
      }
      return { success, message };
    }
  }
  const handleSendWinPot = async (potValue) => {
    // console.log("handle Send pot");
    // console.log(sessionEnded);
    let status;
    console.log(potValue);
    if (initiated && currentStatus) {
      if (currAddress === currentStatus._rektMasterAddress) {
        const { success, message } = await sendWinPot(potValue);
        // console.log(status);
        if (success) {
          status = true;
          return { status, message };
        } else {
          return { status, message };
        }
      }
    }
  };

  return (
    <>
      <InfoContract.Provider
        value={{
          initiated, //contract initiated bool
          unrektusContract, //contract instance for any change
          tokenNative,
          isLoadingStatus,
          isLoadingData,
          currentStatus, //current status
          checkStatus,
          unrektusSession, //current session ID
          // sessionBreak, //session break bool
          sessionEnded, // session ended bool
          sessionDuration, // session duration
          // breakDuration,
          prizeValue, // winning pot
          submissionFee, //submission fee in rekt,
          boostFeePerX,
          bribeTax,
          checkUserSubmissionInfo, // user submission info
          checkUserVotesInfo, // user vote info
          getSubmissionPropertiesAll, // all submissions
          submissionDetails,
          leaderboardData,
          getLastWinnerInfo,
          lastWinner,
          winnerPicked,
          handleSubmit, // write submit
          handleVote, // write vote
          handleBribe, // write bribe
          handleWithdrawBribe, // write withdraw bribe
          handleBoost,
          handleClaimBribe, // write claim bribe
          handlePickWinner,
          handleSendWinPot,
          handleIncreaseSession,
        }}
      >
        {children}
      </InfoContract.Provider>
    </>
  );
}
