import { useState, useEffect, useCallback, updateState } from "react";

import { Pie, PieChart, Sector, Cell, ResponsiveContainer } from "recharts";

const RADIAN = Math.PI / 180;
const COLORS = ["#ff4c4f", "#22c55e"];
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function PortfolioChart(props) {
  const [ratioAvg, setRatioAvg] = useState(0);
  const [REKTdata, setREKTdata] = useState(null);
  const [avgGain, setAvgGain] = useState(0);
  const [madeItCount, setMadeItCount] = useState(0);
  const [rektCount, setRektCount] = useState(0);

  function getRektPortfolio() {
    var nbREKT = props.unRektness.nbRekt;
    var nbMADEIT = props.unRektness.nbMadeit;
    // console.log(nbREKT, nbMADEIT);

    var len = nbREKT + nbMADEIT;

    let RatioAcc = props.unRektness.ratioTotal;

    let ProportionREKT = (100 * nbREKT) / len;
    let ProportionMADEIT = (100 * nbMADEIT) / len;
    let RatioAvg = parseFloat(RatioAcc / len);

    // console.log(ProportionREKT, ProportionMADEIT);
    // average gains
    let avgGain = 0;
    if (RatioAvg >= 1) {
      avgGain = RatioAvg * 100;
    } else {
      avgGain = (1 - RatioAvg) * 100;
    }
    setAvgGain(avgGain.toFixed(2));
    setRatioAvg(RatioAvg.toFixed(2));
    setMadeItCount(ProportionMADEIT);
    setRektCount(ProportionREKT);

    let REKTratioData = [
      {
        name: "REKT",
        value: ProportionREKT,
      },
      {
        name: "MADEIT",
        value: ProportionMADEIT,
      },
    ];
    setREKTdata(REKTratioData);
  }

  useEffect(() => {
    // console.log(props.unRektness);
    if (props.unRektness) {
      //   console.log("unrektness");
      // console.log(currAddress, currChainId);
      // setPastVoteDiv([]);
      // setREKTdata(null);
      // setAvgGain(0);
      // setRatioAvg(0);
      // setMadeItCount(0);
      getRektPortfolio();
    }
  }, [props.unRektness]);

  return (
    <div className="flex flex-col mt-[-40px] items-center justify-center">
      <div className="relative flex flex-col items-center bg-gradient-to-r from-white to-[#26f5d8] via-blue-300 rounded-lg py-1 px-1">
        <div className="z-10 relative flex flex-col items-center w-full border-[8px] bg-[#110a26] border-[#110a26] rounded-lg'">
          <h1 className="text-[#ff31e9] pt-4 text-center text-[25px] uppercase font-extrabold tracking-wider">
            REKT profile
          </h1>
          <p className="text-center mt-0 pb-4 text-[15px] uppercase text-white">
            on <span className="text-[#ff31e9]">{props.chain}</span>
          </p>

          {REKTdata && (
            <>
              {rektCount > 0 ? (
                <>
                  <div className="flex flex-col items-center justify-items-start">
                    <div className="py-2 flex flex-row items-center justify-center">
                      {madeItCount >= 90 && (
                        <h1 className="text-[#4cff85]  border-2 border-[#4cff85] rounded-xl px-2 py-2  text-[30px] uppercase font-extrabold tracking-widest">
                          Bellionaire
                        </h1>
                      )}
                      {madeItCount < 90 && madeItCount > 85 && (
                        <h1 className="text-[#4cffaf] border-2 border-[#4cffaf] rounded-xl px-2 py-2  text-[30px] uppercase font-extrabold tracking-widest">
                          madeit
                        </h1>
                      )}
                      {madeItCount < 85 && madeItCount > 75 && (
                        <h1 className="text-[#00ff9d] border-2 border-[#00ff9d] rounded-xl px-2 py-2 text-[30px] uppercase font-extrabold tracking-widest">
                          giga brain
                        </h1>
                      )}
                      {madeItCount < 75 && madeItCount > 55 && (
                        <h1 className="text-[#54fff1] border-2 border-[#54fff1] rounded-xl px-2 py-2   text-[30px] uppercase font-extrabold tracking-widest">
                          MADEIT
                        </h1>
                      )}
                      {madeItCount < 55 && madeItCount > 50 && (
                        <h1 className="text-[#49e7ff] border-2 border-[#49e7ff] rounded-xl px-2 py-2  text-[30px] uppercase font-extrabold tracking-widest">
                          random pleb +
                        </h1>
                      )}
                      {madeItCount < 50 && madeItCount > 45 && (
                        <h1 className="text-[#f0db36]  border-2 border-[#f0db36] rounded-xl px-2 py-2  text-[30px] uppercase font-extrabold tracking-widest">
                          random pleb -
                        </h1>
                      )}

                      {/* <0 */}
                      {madeItCount < 45 && madeItCount >= 25 && (
                        <h1 className="text-[#ffaa00] border-2 border-[#ffaa00] rounded-xl px-2 py-2 text-[30px] uppercase font-extrabold tracking-widest">
                          Back to maccies
                        </h1>
                      )}
                      {madeItCount < 25 && madeItCount > 5 && (
                        <h1 className="text-[#ff5500] border-2 border-[#ff5500] rounded-xl px-2 py-2 text-[30px] uppercase font-extrabold tracking-widest">
                          Doomer
                        </h1>
                      )}
                      {madeItCount < 5 && madeItCount > 0 && (
                        <h1 className="text-[#ff4040] border-2 border-[#ff4040] rounded-xl px-2 py-2  text-[30px] uppercase font-extrabold tracking-widest">
                          most rekt in universe
                        </h1>
                      )}
                    </div>
                  </div>
                  <div className="mt-1 text-white text-[20px] font-bold flex flex-col justify-center text-center">
                    {/* </div> */}
                    {/* <div className="mt-0 flex items-center justify-center"> */}
                    <div className="flex justify-center">
                      <PieChart width={150} height={150}>
                        <Pie
                          data={REKTdata}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={75}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {REKTdata.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </div>
                    {/* <h2 className='relative'>REKT ratio</h2> */}
                  </div>
                </>
              ) : (
                <div className="flex flex-col items-center justify-items-start">
                  <div className="py-2 flex flex-row items-center justify-center">
                    <h1 className="text-[#4cff85]  border-2 border-[#4cff85] rounded-xl px-2 py-2  text-[30px] uppercase font-extrabold tracking-widest">
                      Safe Player!
                    </h1>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
