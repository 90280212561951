import logo from "./logo.svg";
import "./index.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, { useState, useEffect, useContext, useCallback } from "react";

import Head from "./components/head";
import Submit from "./pages/Submit";
import Vote from "./pages/Vote";
import Nft from "./pages/Nft";
import Loserboard from "./pages/Loserboard";
import Analytics from "./pages/Analytics";
import Portfolio from "./pages/Portfolios";

import { Web3Context } from "./web3/Web3Context";
import { ContractContext } from "./web3/ContractContext";
import { UserPortfolioContext } from "./web3/UserPortfolio";
import { FaunaDatabaseContext } from "./utils/Database";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// WALLET CONNECT
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { WagmiProvider } from "wagmi";
import { hardhat, mainnet } from "wagmi/chains";

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "eaa583765c41fec04b8208c1a1c16cd0";

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

// 0. Setup queryClient
const queryClient = new QueryClient();
export const walletImages = [
  {
    metaMask: "/src/public/images/dclogo.webp",
  },
];

// CUSTOM CHAINS
// DOGECHAIN
const dc = {
  /** ID in number form */
  id: 2000, //0x7d0
  /** Human-readable name */
  name: "DogeChain",
  /** Internal network name */
  network: "Dogechain",
  /** Currency used by chain */
  nativeCurrency: { name: "DOGE", symbol: "DOGE", decimals: 18 },
  /** Collection of RPC endpoints */
  rpcUrls: {
    public: { http: ["https://rpc.dogechain.dog"] },
    default: { http: ["https://rpc.dogechain.dog"] },
  },
  testnet: false,
};
const dc_test = {
  /** ID in number form */
  id: 568, //0x7d0
  /** Human-readable name */
  name: "Dogechain Testnet",
  /** Internal network name */
  network: "Dogechain",
  /** Currency used by chain */
  nativeCurrency: { name: "DOGE", symbol: "DOGE", decimals: 18 },
  /** Collection of RPC endpoints */
  rpcUrls: {
    public: { http: ["https://rpc-testnet.dogechain.dog"] },
    default: { http: ["https://rpc-testnet.dogechain.dog"] },
  },
  testnet: false,
};
// Fantom
const ftm = {
  /** ID in number form */
  id: 250, //0xfa
  /** Human-readable name */
  name: "Fantom",
  /** Internal network name */
  network: "Fantom Opera",
  /** Currency used by chain */
  nativeCurrency: { name: "ftm", symbol: "FTM", decimals: 18 },
  /** Collection of RPC endpoints */
  rpcUrls: {
    public: { http: ["https://fantom.publicnode.com"] },
    default: { http: ["https://fantom.publicnode.com"] },
  },
  testnet: false,
};

// 2. Set chains
const sonic_test = {
  id: 64165, //0xfaa5
  /** Human-readable name */
  name: "Sonic Testnet",
  /** Internal network name */
  network: "Sonic Testnet",
  nativeCurrency: { name: "Sonic", symbol: "S", decimals: 18 },
  explorerUrl: "https://testnet.soniclabs.com/",
  rpcUrls: {
    public: { http: ["https://rpc.testnet.soniclabs.com/"] },
    default: { http: ["https://rpc.testnet.soniclabs.com/"] },
  },
  testnet: true,
};

// default is first chain in "chains" array
// const chains = [ftm, dc, hardhat,dc_test, metis, , mainnet];
const chains = [ftm, dc, sonic_test, hardhat];
// default is first chain in "chains" array
// const chains = [hardhat, dc, ftm, eth];

// // Wagmi client
// const { provider } = configureChains(chains, [
//   w3mProvider({ projectId: "eaa583765c41fec04b8208c1a1c16cd0" }),
// ]);

// const wagmiClient = createConfig({
//   autoConnect: true,
//   connectors: w3mConnectors({
//     projectId: "eaa583765c41fec04b8208c1a1c16cd0",
//     version: "1", // or "2"
//     appName: "Unrektus",
//     chains,
//   }),
//   provider,
// });

// // Web3Modal Ethereum Client
// const ethereumClient = new EthereumClient(wagmiClient, chains);
// 2. Create wagmiConfig
const config = defaultWagmiConfig({
  chains, // required
  projectId, // required
  metadata, // required
  enableWalletConnect: true, // Optional - true by default
  enableInjected: true, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: true, // Optional - true by default
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  themeVariables: {
    "--w3m-accent": "#379EB1",
  },
});

function App() {
  const [ready, setReady] = useState(false);
  // const { theme, setTheme } = useWeb3ModalTheme();

  useEffect(() => {
    setReady(true);
  }, []);

  // Function to set modal's themeMode and themeVariables options
  // setTheme({
  //   themeMode: "dark",
  //   themeBackground: "gradient",
  //   themeVariables: {
  //     "--w3m-font-family": "Roboto, sans-serif",
  //   },
  // });

  return (
    <>
      {ready ? (
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            {/* <ReactQueryDevtools initialIsOpen={true} /> */}
            <Router>
              <FaunaDatabaseContext>
                <Web3Context>
                  <ContractContext>
                    <UserPortfolioContext>
                      <Head />
                      <div className="w-full px-0 2xl:px-[150px] flex flex-col justify-center items-center">
                        <Routes>
                          <Route path="/" element={<Submit />} />
                          <Route path="/vote" element={<Vote />} />
                          <Route path="/loserboard" element={<Loserboard />} />
                          <Route path="/analytics" element={<Analytics />} />
                          <Route path="/portfolio" element={<Portfolio />} />
                          <Route path="/nft" element={<Nft />} />
                          <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                      </div>
                    </UserPortfolioContext>
                  </ContractContext>
                </Web3Context>
              </FaunaDatabaseContext>
            </Router>
          </QueryClientProvider>
        </WagmiProvider>
      ) : null}
    </>
  );
}

export default App;
