import React from "react";
import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";

export default function RektTxnDiv(props) {
  // var currentChain = props.currentChain;
  const { currAddress, currChainId } = UseInfoUser();
  const { tokenNative } = UseContractInfo();
  // var currentChain = "0x7d0"; //DC
  var token1AmountBuy = props.rektxn[2];
  var token1SymbolBuy = props.rektxn[3];
  var token2AmountBuy = props.rektxn[0];
  var token2SymbolBuy = props.rektxn[1];
  var token2AmountSell = props.rektxn[6];
  var token2SymbolSell = props.rektxn[7];
  var token1AmountSell = props.rektxn[4];
  var token1SymbolSell = props.rektxn[5];
  var TxnBuy = props.rektxn[10];
  var TxnSell = props.rektxn[11];
  var ratio = props.rektxn[12]; //rektTxn[9] / rektTxn[8];
  var unrektusPotential = props.rektxn[13];
  var rektyn = props.rektxn[14];
  var txnID = props.rektxn[15];
  var percent = props.rektxn[16];
  var conversion = props.rektxn[17];

  const findChainExplorer = () => {
    var link = "";
    // console.log(currentChain);
    switch (currChainId) {
      case "0x7d0": //DC
        link = "https://explorer.dogechain.dog/tx/";
        break;
      case "0xfa": //FTM
        link = "https://ftmscan.com/tx/";
        break;
      case "0x1": //eth
        link = "https://etherscan.io/tx/";
        break;
      default:
        link = "";
    }
    return link;
  };

  return (
    <>
      <span className="w-[200px] max-w-[170px] px-2 py-5">
        <p className="text-[9px] text-gray-500 font-bold uppercase">
          You bought:
        </p>
        <p className="text-[12px] text-white font-bold uppercase">
          {token1AmountBuy.toFixed(1)} {token1SymbolBuy} for{" "}
          {token2AmountBuy.toFixed(1)} {token2SymbolBuy}
        </p>
      </span>
      <span className=" px-2 py-5 w-[200px] max-w-[180px]">
        <p className="text-[9px] text-gray-500 font-bold uppercase">
          You sold:
        </p>
        <p className="text-[12px] text-white font-bold ">
          {token1AmountSell.toFixed(1)} {token1SymbolSell} for{" "}
          {token2AmountSell.toFixed(1)} {token2SymbolSell}
        </p>
      </span>
      <span className="px-2 py-5 flex flex-col text-left justify-center w-[200px] max-w-[180px]">
        <p className="mb-2 md:hidden text-white mr-3">(Profit/Loss)</p>
        {rektyn === "rekt" && (
          <>
            <p className="text-[16px] text-[#ff31e9] font-bold">
              {unrektusPotential.toFixed(2)} {token2SymbolSell}
            </p>
            {conversion < 0 && (
              <p className="text-[12px] text-orange-400 font-bold">
                ({conversion.toFixed(2)} {tokenNative})
              </p>
            )}
          </>
        )}
        {rektyn === "madeit" && (
          <>
            <p className="text-[16px] text-green-500 font-bold ">
              {"+" + parseInt(unrektusPotential)} {token2SymbolSell}
            </p>
          </>
        )}
      </span>
      <span className="px-2 py-5 flex flex-col text-left justify-center w-[150px] max-w-[130px]">
        <p className="mb-2 md:hidden text-white mr-3">(Gainz %)</p>
        {ratio < 1 && (
          <p className="text-[12px] text-white font-bold ">
            -{Number(percent).toFixed(2)}&nbsp;%
            {/* -{(100 * (1 - ratio)).toFixed(2)}&nbsp;% */}
          </p>
        )}
        {ratio >= 1 && (
          <p className="text-[12px] text-white font-bold ">
            {Number(percent).toFixed(2)}&nbsp;%
            {/* {(100 * ratio).toFixed(2)}&nbsp;% */}
          </p>
        )}
      </span>
      <span className="flex px-4 py-5 justify-left items-center w-[200px] max-w-[180px]">
        <button
          className="text-white border-[1px] py-[5px] px-[20px] rounded-lg border-[#d430b0] cursor-fancy"
          onClick={() => {
            window.open(findChainExplorer() + TxnBuy, "_blank"); //to open new page
          }}
        >
          <p className="text-[11px] font-bold">TXN</p>
          <p className="text-[11px] font-bold mt-[-5px]">Buy</p>
        </button>
        <button
          className="text-white border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0]"
          onClick={() => {
            window.open(findChainExplorer() + TxnSell, "_blank"); //to open new page
          }}
        >
          <p className="text-[11px] font-bold">TXN</p>
          <p className="text-[11px] font-bold mt-[-5px]">Sell</p>
        </button>
      </span>
    </>
  );
}
