import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { UseInfoUser } from "./Web3Context";
import { UseContractInfo } from "./ContractContext";
import { ethers } from "ethers";
import {
  REKT_DODGE,
  REKT_DODGE_TEST,
  REKT_ETH,
  REKT_FTM,
  REKT_HARDHAT,
  WNATIVE_DODGE,
  WNATIVE_DODGE_TEST,
  WNATIVE_ETH,
  WNATIVE_FTM,
  WNATIVE_HARDHAT,
} from "./config";
import { initTabs } from "flowbite";

export const UserPortfolio = React.createContext();
// hook
export function UseUserPortfolio() {
  return useContext(UserPortfolio);
}

export function UserPortfolioContext({ children }) {
  const {
    currProvider,
    currSigner,
    currAddress,
    currChainId,
    wrongNetwork,
    comingSoon,
  } = UseInfoUser();
  const {
    initiated,
    currentStatus,
    unrektusSession,
    unrektusContract,
    checkUserVotesInfo,
    getSubmissionPropertiesAll,
    checkUserSubmissionInfo,
  } = UseContractInfo();

  const [provider, setProvider] = useState(currProvider);
  const [signer, setSigner] = useState(currSigner);
  // const [chainId, setChainId] = useState(currChainId);

  const [submissionPower, setSubmissionPower] = useState(0);
  const [submissionToken, setSubmissionToken] = useState("");
  const [rektPower, setRektPower] = useState(0);
  const [unRektness, setRektness] = useState(null);

  const [dataChain, setDataChain] = useState("");
  const [queriesEnable, setQueriesEnable] = useState(false);
  const [queriesEnableSonic, setQueriesEnableSonic] = useState(false);
  const [howRektPressed, setHowRektPressed] = useState(0);
  const [howRektPressedSonic, setHowRektPressedSonic] = useState(0);

  const [userCurrentVotes, setUserCurrentVotes] = useState(null);
  const [userPastVotes, setUserPastVotes] = useState(null);
  const [userCurrentSubmission, setUserCurrentSubmission] = useState(null);
  const [userPastSubmission, setUserPastSubmission] = useState(null);
  // const [data, setData] = useState();

  const minABI_rektToken = [
    // balanceOf
    "function balanceOf(address) external view returns(uint256)",
    "function symbol() external view returns(string)",
  ];
  useEffect(() => {
    if (provider !== currProvider) setProvider(currProvider);
    if (signer !== currSigner) setSigner(currSigner);
    // if (chainId !== currChainId) setChainId(currChainId);
  }, [currProvider, currSigner]);

  // const addi = "0x0565978e02bb9bdAe46D599a55Ab1cBC0df45E9C"; //krakouz
  const addi = "0xeAf3Eda82325A1d2dC4e5681d09d1FF27bB3B826"; // random ftm addi
  // const addi = "0x74fF926D8dC46984b09c9C11247bEd8dc2B60B73"; //random
  // const addi = "0x95E343297bF06616A43511A345294F7FAC2CE7b9"; // krakouz freind
  // const addi = "0xb2d2da2f7829e26393a758b5D15a9A3F3d35BEe8"; //random 2
  // const addi = "0xFbD871dbCF9fa0b5d2ba9Aa9E4A57e6790277cc4"; //ftm
  // const addi = "0x9307caa51a02177c26d666e628a156a5bd8931bb"; //shiba ftm
  // const chain = "0xfa"; //ftm
  // const chain = "0x7d0"; //dodge
  // const chain = "0x1";

  const fetchData = async (addi, chain) => {
    if (!wrongNetwork && !comingSoon) {
      //hardhat
      // ftm
      if (addi && chain === "0xfa") {
        // console.log("Getting FTM data", addi);
        // let addi = "0xeAf3Eda82325A1d2dC4e5681d09d1FF27bB3B826"; // random ftm addi
        // let addi = "0xC0758Ee47Ce20A6a96526FcbAAF226F0F765E992"; // doomed ftm addi
        // let addi = "0xe328BA780dFc7ecd51cCEEb04a7F7D90A58ca052"; //  ftm addi

        const res = await axios(
          `https://api.ftmscan.com/api?module=account&action=tokentx&address=${addi}&startblock=0&endblock=99999999&apikey=E1PPSEVRECAE19MB2WRWXBUKKYZXF8PXRM`
        );
        // console.log(res);
        setDataChain("Fantom Opera");
        setQueriesEnable(false);
        return res;
      }
      // dodge
      else if (addi && chain === "0x7d0") {
        // console.log("Getting data dodge", addi);
        let addi = "0x95E343297bF06616A43511A345294F7FAC2CE7b9"; // krakouz freind
        // let addi = "0x53270d44eee663E509f0b715525703B12c353e30"; // krakouz freind

        const res = await axios(
          `https://explorer.dogechain.dog/api?module=account&action=tokentx&address=${addi}`
        );
        // console.log(res);
        setDataChain("DOGE");
        setQueriesEnable(false);
        return res;
      }
      // dodge test
      else if (addi && chain === "0x238") {
        // console.log("Getting data dodge", addi);
        const res = await axios(
          `https://explorer.dogechain.dog/api?module=account&action=tokentx&address=${addi}`
        );
        // console.log(res);
        setDataChain("DOGE");
        setQueriesEnable(false);
        return res;
      }
      // harhdat
      else if (addi && chain === "0x7a69") {
        // console.log("Getting data dodge", addi);
        const res = await axios(
          `https://explorer.dogechain.dog/api?module=account&action=tokentx&address=${addi}`
        );
        // console.log(res);
        setDataChain("DOGE");
        setQueriesEnable(false);
        return res;
      } else if (addi && chain === "0xfaa5") {
        console.log("Getting data ftm on sonic", addi);
        const res = await axios(
          `https://api.ftmscan.com/api?module=account&action=tokentx&address=${addi}&startblock=0&endblock=99999999&apikey=E1PPSEVRECAE19MB2WRWXBUKKYZXF8PXRM`
        );
        // console.log(res);
        setDataChain("Fantom Opera");
        setQueriesEnable(false);
        return res;
      }
    }
  };

  const {
    isLoading,
    data,
    error: errorTxn,
    refetch,
  } = useQuery({
    queryKey: ["Txns"],
    queryFn: () => fetchData(addi, currChainId),
    enabled: queriesEnable,
  });

  /////////////////// Option for sonic data //////////////
  const fetchDataSonic = async (addi, chain) => {
    if (!wrongNetwork && !comingSoon) {
      if (addi && chain === "0xfaa5") {
        console.log("Getting data sonic", addi);
        const res = await axios(
          `https://api.routescan.io/v2/network/testnet/evm/64165/etherscan/api?module=account&action=balance&address=0xde0b295669a9fd93d5f28d9ec85e40f4cb697bae&tag=latest&apikey=YourApiKeyToken?
          module=account&action=tokentx&address=${addi}&startblock=0&endblock=99999999&apikey=E1PPSEVRECAE19MB2WRWXBUKKYZXF8PXRM`
        );
        // console.log(res);
        setQueriesEnableSonic(false);
        return res;
      }
    }
  };
  const {
    isLoading: isLoadingSonic,
    data: dataSonic,
    error: errorTxnSonic,
    refetch: refetchSonic,
  } = useQuery({
    queryKey: ["Txns S"],
    queryFn: () => fetchDataSonic(currAddress, currChainId),
    enabled: queriesEnableSonic,
  });

  const setUnRektness = (nbRekt, nbMadeit, ratioTotal) => {
    // console.log("setUnRektness");
    setRektness({
      nbRekt: nbRekt,
      nbMadeit: nbMadeit,
      ratioTotal: ratioTotal,
    });
  };

  // const setCurrentVotes = (array) => {
  //   setUserCurrentVotes(array);
  // };
  async function getSubmissionPower(signer) {
    let wrappedTokenAddress = "";
    // console.log(currChainId);
    if (signer) {
      switch (currChainId) {
        case "0x7a69": //hardhat
          wrappedTokenAddress = WNATIVE_HARDHAT;
          break;
        case "0x7d0": //dc
          wrappedTokenAddress = WNATIVE_DODGE;
          break;
        case "0x238":
          wrappedTokenAddress = WNATIVE_DODGE_TEST;
          break;
        case "0xfa": //ftm
          wrappedTokenAddress = WNATIVE_FTM;
          break;
        case "0x1": //eth
          wrappedTokenAddress = WNATIVE_ETH;
          break;
        default:
          wrappedTokenAddress = "";
      }
      try {
        if (wrappedTokenAddress !== "") {
          const wrappedContract = new ethers.Contract(
            wrappedTokenAddress,
            minABI_rektToken,
            signer
          );

          const tokenName = await wrappedContract.symbol();
          console.log(tokenName);
          setSubmissionToken(tokenName);
          const result = await wrappedContract.balanceOf(currAddress); // 29803630997051883414242659
          const balanceWrapped = Number(ethers.formatEther(result)).toFixed(2); // 29803630.997051883414242659
          // const balanceRekt = 0;
          // console.log("wrappedContract", wrappedContract);
          console.log("result", result);
          console.log("Balance", balanceWrapped);
          setSubmissionPower(balanceWrapped);
        }
      } catch (error) {
        console.log(error);
        console.log("Coudn't get WrappedToken balance");
      }
    }
  }
  async function getRektPower(signer) {
    // console.log("get rekt power");
    // console.log(currChainId);
    let rektTokenAddress = "";

    if (signer) {
      switch (currChainId) {
        case "0x7a69": //harhat
          rektTokenAddress = REKT_HARDHAT;
          break;
        case "0x7d0": //dc
          rektTokenAddress = REKT_DODGE;
          break;
        case "0x238": //dc test
          rektTokenAddress = REKT_DODGE_TEST;
          break;
        case "0xfa": //ftm
          rektTokenAddress = REKT_FTM;
          break;
        case "0x1": //eth
          rektTokenAddress = REKT_ETH;
          break;
        default:
          rektTokenAddress = "";
      }
      console.log(rektTokenAddress);
      try {
        if (rektTokenAddress !== "") {
          const rektContract = new ethers.Contract(
            rektTokenAddress,
            minABI_rektToken,
            signer
          );
          // console.log(rektContract);
          // const tokenName = await rektContract.symbol();
          // console.log(tokenName);
          const result = await rektContract.balanceOf(currAddress); // 29803630997051883414242659
          const balanceRekt = parseInt(ethers.formatEther(result)); // 29803630.997051883414242659
          // const balanceRekt = 0;
          console.log("Balance Rekt", balanceRekt);

          setRektPower(balanceRekt);
        }
      } catch (error) {
        console.log(error);
        console.log("Coudn't get REKT balance");
      }
    }
  }
  async function getUserPastVotes() {
    var { userCurrentVotes, userPastVotes } = await checkUserVotesInfo();

    setUserCurrentVotes(userCurrentVotes);
    setUserPastVotes(userPastVotes);
    return { userCurrentVotes, userPastVotes };
  }
  async function getUserPastSubmissions() {
    var {
      alreadySubmitted,
      currentSubmissionCounts,
      currentSubmissionInfo,
      won,
      wonSubmissions,
      pastSubmissionInfo,
    } = await checkUserSubmissionInfo();

    const userCurrentSubmission = {
      alreadySubmitted: alreadySubmitted,
      currentSubmissionCounts: currentSubmissionCounts,
      currentSubmissionInfo: currentSubmissionInfo,
    };
    const userPastSubmission = {
      won: won,
      wonSubmissions: wonSubmissions,
      pastSubmissionInfo: pastSubmissionInfo,
    };
    setUserCurrentSubmission(userCurrentSubmission);
    setUserPastSubmission(userPastSubmission);
    // console.log(userCurrentSubmission, userPastSubmission);
    return { userCurrentSubmission, userPastSubmission };
  }

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      if (signer && provider) {
        if (!wrongNetwork) {
          if (!comingSoon) {
            console.log("changeDetected... Fetching new data");
            // setRektDivs([]);
            // setrektTxns([]);
            // setNbRekt(0);
            // setNbMadeit(0);
            // setUnrektNumber(0);
            setQueriesEnable(true);
            setHowRektPressed(0);
            refetch();
            if (currChainId === "0xfaa5") {
              console.log("Sonic chain");
              setQueriesEnableSonic(true);
              // setHowRektPressedSonic(0);
              refetchSonic();
            }

            // setGoAutoParse(true);
          }
        }
      }
    }
    return () => {
      isCurrent = false;
    };
  }, [signer, currChainId]);

  useEffect(() => {
    if (initiated) {
      const getData = async () => {
        await getRektPower(signer);
        await getSubmissionPower(signer);
      };
      getData();
    }
  }, [initiated]);

  useEffect(() => {
    if (unrektusContract != null) {
      // console.log("get User Vote/Submission Data");
      const getData = async () => {
        try {
          await getUserPastVotes();
          await getUserPastSubmissions();
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }
  }, [unrektusContract]);

  return (
    <>
      <UserPortfolio.Provider
        value={{
          submissionToken,
          submissionPower,
          rektPower,
          getRektPower,
          unRektness,
          setUnRektness,
          dataChain,
          data,
          isLoading,
          errorTxn,
          queriesEnable,
          setQueriesEnable,
          howRektPressed,
          setHowRektPressed,
          dataSonic,
          isLoadingSonic,
          errorTxnSonic,
          queriesEnableSonic,
          userCurrentVotes,
          userPastVotes,
          getUserPastVotes,
          userCurrentSubmission,
          userPastSubmission,
          getUserPastSubmissions,
        }}
      >
        {children}
      </UserPortfolio.Provider>
    </>
  );
}
