import { Link } from "react-router-dom";
import { Navbar } from "flowbite-react";

const barNav = () => {
	return (
		<>
			<Navbar
				fluid
				className='z-20 mr-5 md:mr-0 border-gray-200 justify-start dark:bg-gray-900'
				theme={{
					root: {
						inner: {
							base: "mx-auto flex flex-wrap items-center justify-start"
						}
					}
				}}
			>
				<Navbar.Toggle
					className='inline-flex items-center justify-center px-4 py-2 w-10 h-10  text-sm text-gray-500 rounded-lg md:hidden focus:outline-none'
					theme={{
						toggle: {
							base: "inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:text-gray-100 md:hidden",
							icon: "h-6 w-6 shrink-0"
						}
					}}
				/>
				{/* <button
            data-collapse-toggle="navbar-default"
            type="button"
            class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button> */}
				<Navbar.Collapse
					className='mt-0 py-4 md:flex md:w-auto'
					id='navbar-default'
					theme={{
						list: "mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium"
					}}
				>
					{/* <div class="flex flex-wrap items-center justify-between p-4"> */}
					<ul className='text-[18px] w-full flex flex-col justify-start items-start mt-0 mr-4 absolute z-20 overflow-y-auto  bg-[#191236]/100 border-b-2 border-gray-500 md:relative text-xl text-white md:p-0 rounded-lg md:flex-row md:space-x-14 rtl:space-x-reverse md:mt-0 md:border-0'>
						<Navbar.Link
							href='/'
							className='block py-2 px-4 rounded hover:text-[#26f5d8]
                  md:border-0 md:p-0'
							aria-current='page'
						>
							Submit
						</Navbar.Link>

						<Navbar.Link
							href='/vote'
							className='block py-2 px-4 rounded hover:text-[#26f5d8] md:border-0  md:p-0'
						>
							Vote
						</Navbar.Link>
						<Navbar.Link
							href='/loserboard'
							className='block py-2 px-4 rounded hover:text-[#26f5d8] md:border-0  md:p-0'
						>
							Loserboard
						</Navbar.Link>
						<Navbar.Link
							href='/portfolio'
							className='block py-2 px-4 rounded hover:text-[#26f5d8] md:border-0  md:p-0'
						>
							Claim
						</Navbar.Link>

						{/* <Navbar.Link
              href="/analytics"
              className="block py-2 px-4 rounded hover:text-blue-700 md:border-0  md:p-0"
            >
              Analytics
            </Navbar.Link> */}

						{/* <Navbar.Link
							href='/nft'
							className='block py-2 px-4 font-bold rounded hover:text-[#26f5d8] md:border-0  md:p-0'
						>
							NFT
						</Navbar.Link> */}
					</ul>
					{/* </div> */}
				</Navbar.Collapse>
			</Navbar>

			{/* <nav>
				<ul className='text-white font-semibold flex flex-row items-center justify-center text-xs space-x-3  sm:text-xl sm:space-x-10  '>
					<li className='hover:text-[#ff31e9] underline-offset-8 uppercase'>
						<Link to='/'>Submit</Link>
					</li>
					<li className='hover:text-[#ff31e9] underline-offset-8 uppercase'>
						<Link to='/vote'>Vote</Link>
					</li>
					<li className='hover:text-[#ff31e9] underline-offset-8 uppercase'>
						<Link to='/leaderboards'>Leaderboard</Link>
					</li>
					<li className='hover:text-[#ff31e9] underline-offset-8 uppercase'>
						<Link to='/Portfolios'>Portfolio</Link>
					</li>
					<li className='hover:text-[#ff31e9] underline-offset-8 uppercase'>
						<Link to='/analytics'>Analytics</Link>
					</li>
					<li className='hover:text-[#ff31e9] underline-offset-8 uppercase'>
						<Link to='/nft'>NFT</Link>
					</li>
				</ul>
			</nav> */}
		</>
	);
};

export default barNav;
