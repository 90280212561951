import React, { useState, useEffect, useCallback, useContext } from "react";
import { ReactComponent as Logo } from "../public/images/sortPyramids.svg";
import { ReactComponent as LoadingLogo } from "../public/images/loadingblue.svg";
import LoadingSkeleton from "./loadingSkeleton";
import RektTxnDiv from "./rektxndiv";
import axios from "axios";
import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";

export default function SubmitDiv(props) {
  const { connected, currAddress, currChainId, wrongNetwork, comingSoon } =
    UseInfoUser();
  const { sessionEnded } = UseContractInfo();

  const [rektDivs, setRektDivs] = useState([]);
  const [gainDivs, setGainDivs] = useState([]);
  const [sortField, setSortField] = useState([13, 1, 2]);
  const [sortedName, setsortedName] = useState(0);
  const [sortedREKTp, setsortedREKTp] = useState(0);
  const [sortedREKTb, setsortedREKTb] = useState(0);
  const [unrektNumber, setUnrektNumber] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const checkCurrentSubmissions = (submission, userInfo) => {
    var condition = false;
    // if (userInfo.currentSubmissionInfo.length > 0) {
    userInfo.currentSubmissionInfo.forEach((sub) => {
      if (sub.buyTxn === submission[10] && sub.sellTxn === submission[11]) {
        // console.log(submission);
        // console.log(sub);
        condition = true;
        return true;
      }
    });
    // }
    return condition;
  };

  function checkPastWins(submission, userInfo) {
    var condition = false;
    var totalWon = 0;
    // if (userInfo.wonSubmissions.length > 0) {
    userInfo.wonSubmissions.forEach((won) => {
      if (won.buyTxn === submission[10] && won.sellTxn === submission[11]) {
        // console.log(submission);
        // console.log(won);
        condition = true;
        totalWon = won.totalPotWon;
        return true;
      }
    });
    // }
    return { condition, totalWon };
  }
  const tokenPairInfo = async (tokenSymbol) => {
    // console.log("gettingPairData ", tokenSymbol);
    var query;
    var filteredData;
    var res;
    switch (currChainId) {
      case "0xfa":
        let token = tokenSymbol;
        // console.log(tokenSymbol);
        if (tokenSymbol === "USDC") {
          token = "USDC.e";
        }
        query = `https://api.dexscreener.io/latest/dex/search/?q=FTM%20${token}`;
        // setTokenNative("FTM");
        res = await axios(query);
        break;
      case "0x7d0":
        // console.log(tokenSymbol);
        query = `https://api.dexscreener.io/latest/dex/search/?q=wDOGE%20${tokenSymbol}`;
        // setTokenNative("DOGE");
        res = await axios(query);
        break;
      case "0x238": //dc test
        query = `https://api.dexscreener.io/latest/dex/search/?q=wDOGE%20${tokenSymbol}`;
        // setTokenNative("DOGE");
        res = await axios(query);
        break;
      case "0x7a69": //hardhat
        query = `https://api.dexscreener.io/latest/dex/search/?q=wDOGE%20${tokenSymbol}`;
        // setTokenNative("DOGE");
        res = await axios(query);
        break;
      default:
        query = ``;
    }
    // console.log(res);
    return res;
  };
  const filterByDex = (obj, tokenSymbol) => {
    if (currChainId === "0xfa") {
      var dex = "spookyswap";
      let token = tokenSymbol;
      // console.log(tokenSymbol);
      if (tokenSymbol === "USDC") {
        token = "USDC.e";
      }
      if (
        obj.chainId === "fantom" &&
        // obj.dexId === dex &&
        obj.baseToken.symbol === token
        // obj.pairAddress === "0x90469ACbC4b6d877873CD4f1CCA54fDE8075A998"
        //TODO some work left find right dex pairing
      ) {
        return true;
      } else return false;
    } else if (
      currChainId === "0x7d0" ||
      currChainId === "0x238" ||
      currChainId === "0x7a69"
    ) {
      // console.log(currChainId);
      //dc and dc test
      var dex = "dogeswap";
      // console.log(obj);

      if (obj.chainId === "dogechain" && obj.baseToken.symbol === tokenSymbol) {
        return true;
      } else return false;
    } else return false;
  };
  function tokenConversion(rektxn, priceNative, priceUsd) {
    return rektxn[13] * priceNative;
  }

  async function getTokenConversion(rektTxn) {
    const tokenSymbol = rektTxn[7];
    const data = await tokenPairInfo(tokenSymbol);

    if (data) {
      //   console.log(data);
      let arr = [];
      arr.push(data?.data?.pairs ? data.data.pairs : null);
      //   console.log(arr);
      const result = arr[0].filter((obj) => filterByDex(obj, tokenSymbol));
      //   console.log(result);
      //TODO better algo for other than usdc
      if (result[0]) {
        // console.log(result[0].priceNative, result[0].priceUsd);
        const conversion = tokenConversion(
          rektTxn,
          result[0].priceNative,
          result[0].priceUsd
        );
        // console.log(conversion);
        return conversion;
      }
    }
  }

  function handleClick(rektTxn) {
    // setSelectedTxn(rektTxn);
    console.log(rektTxn);
    props.handleSubmitPopUp(rektTxn);
  }

  async function getRektDivs(rektedTxns, userInfo) {
    var array = [];
    var loss = [];
    var rest = [];
    var gain = [];
    var select;
    setUnrektNumber(parseInt(rektedTxns.length));
    rektedTxns.sort(sortFunction);

    for (var rektTxn of rektedTxns) {
      // var ratio = rektTxn[12]; //rektTxn[9] / rektTxn[8];
      let select = rektTxn;
      const UnrektusPotential = select[13];
      const rektyn = select[14];
      const txnID = select[15];

      let conversion = 0;

      if (parseInt(UnrektusPotential) < 0) {
        // console.log(txnID);
        conversion = await getTokenConversion(select);
        // console.log(conversion);
        select.splice(17, 0, conversion);

        if (conversion < 0) {
          var { condition, totalWon } = checkPastWins(select, userInfo);
          // if (condition === true) {
          //   console.log(condition, totalWon);
          //   console.log(rektTxn);
          // }
          select.splice(18, 0, totalWon);

          var submitted = checkCurrentSubmissions(select, userInfo);
          //   console.log(rektTxn);
          loss.push(
            <div
              className="pb-5 flex flex-wrap justify-start bg-[#191236] opacity-100  border-b-[1px] border-gray-400"
              key={txnID}
            >
              <RektTxnDiv rektxn={select} currentChain={currChainId} />
              <span className="flex flex-col px-2 py-5 justify-center items-center w-[120px] max-w-[100px]">
                {!wrongNetwork && !comingSoon && !sessionEnded ? (
                  <>
                    <>
                      {submitted === true ? (
                        <button className="text-[#26f5d8] ml-1 cursor-default animate-pulse">
                          <a href="/vote">{"Manage"}</a>
                        </button>
                      ) : (
                        <>
                          {userInfo.currentSubmissionCounts < 5 && (
                            <>
                              <button
                                className="text-white text-[13px] bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 rounded-lg py-[7.5px] px-[20px] font-bold hover:text-gray-200"
                                onClick={() => {
                                  console.log(conversion);
                                  handleClick(select);
                                }}
                              >
                                {"Submit"}
                              </button>
                              {condition === true && (
                                <p className="text-[13px] text-[#26f5d8] cursor-default animate-pulse">
                                  Past Winner
                                </p>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  </>
                ) : (
                  <></>
                )}
              </span>
            </div>
          );
        } else {
          rest.push(
            <div
              className="pb-5 flex flex-wrap justify-start bg-[#191236] opacity-100  border-b-[1px] border-gray-400"
              key={txnID}
            >
              <RektTxnDiv rektxn={select} currentChain={currChainId} />
              <span className="flex flex-col px-2 py-5 justify-center items-center w-[120px] max-w-[100px]"></span>
            </div>
          );
        }
      } else {
        gain.push(
          <div
            className="pb-5 flex flex-wrap justify-start bg-[#191236] opacity-100  border-b-[1px] border-gray-400"
            key={txnID}
          >
            <RektTxnDiv rektxn={select} currentChain={currChainId} />
            <span className="flex flex-col px-2 py-5 justify-center items-center w-[120px] max-w-[100px]"></span>
          </div>
        );
      }
    }
    //     })
    //   : "";
    // console.log(rektedtxns.length)
    array.push(loss);
    array.push(rest);
    array.push(gain);
    // array.push(gain);
    setRektDivs(array);
    // forceRender();
    // console.log(acc);
    // objs.sort((a,b) => a.last_nom - b.last_nom);
    // return acc;
  }
  function sortFunction(a, b) {
    if (a[sortField[0]] === b[sortField[0]]) {
      return 0;
    } else {
      //   console.log(sortedREKTb);

      if (sortField[1] === 1) {
        if (sortField[2] === 0) {
          setsortedName(0);
        } else if (sortField[2] === 1) {
          setsortedREKTp(0);
        } else if (sortField[2] === 2) {
          setsortedREKTb(0);
        }
        return a[sortField[0]] < b[sortField[0]] ? -1 : 1;
      } else if (sortField[1] === 0) {
        if (sortField[2] === 0) {
          setsortedName(1);
        } else if (sortField[2] === 1) {
          setsortedREKTp(1);
        } else if (sortField[2] === 2) {
          setsortedREKTb(1);
        }
        return a[sortField[0]] > b[sortField[0]] ? -1 : 1;
      }
    }
  }

  useEffect(() => {
    props.setPrepDivs(true);
  }, [sortField]);

  useEffect(() => {
    if (props.prepDivs === true) {
      //   console.log(isLoading);
      setIsLoading(true);
      setRektDivs(null);
      const getDivs = async () => {
        await getRektDivs(props.rektTxns, props.userInfo);
        setIsLoading(false);
        // console.log(isLoading);
        props.setPrepDivs(false);
      };
      getDivs();
    }
  }, [props.prepDivs]);

  return (
    <>
      {isLoading ? (
        <div className="w-full mt-10 flex flex-col items-center justify-center">
          <LoadingSkeleton />
        </div>
      ) : (
        <>
          {unrektNumber > 0 && (
            <>
              <div className="mt-4 flex-col items-center justify-center">
                <div className="relative flex flex-row items-center justify-center">
                  <div className="flex flex-col items-center justify-center">
                    {/* <p className="mb-2 md:hidden text-white justify-start mr-3">
                                  Sort Options
                                </p> */}
                    <nav className="relative w-full flex flex-wrap justify-start items-center">
                      <button
                        className="sm:w-[100px] md:w-[400px] md:max-w-[340px] px-2 text-left relative flex font-bold text-[16px] text-white  hover:text-gray-200"
                        onClick={() => setSortField([1, sortedName, 0])}
                      >
                        Name
                        <Logo className="mt-1.5 ml-1 arrows w-3 h-3"></Logo>
                      </button>
                      <button
                        className="px-2 sm:w-[100px] md:w-[200px] md:max-w-[180px] text-left relative flex font-bold text-[16px] text-white hover:text-gray-200"
                        onClick={() => setSortField([13, sortedREKTb, 2])}
                      >
                        Profit / Loss
                        <Logo className="mt-1.5 ml-1 arrows w-3 h-3 "></Logo>
                      </button>
                      <button
                        className="px-2 sm:w-[100px] md:w-[150px] md:max-w-[130px] text-left relative flex font-bold text-[16px] text-white  hover:text-gray-200"
                        onClick={() => setSortField([12, sortedREKTp, 1])}
                      >
                        Gainz {"(%)"}
                        <Logo className="mt-1.5 ml-1 arrows w-3 h-3"></Logo>
                      </button>
                      <span className="hidden md:flex">
                        <button className="px-2 w-[200px] max-w-[180px] justify-center text-center relative flex font-bold text-[16px] text-white hover:text-gray-200">
                          Transactions
                        </button>
                        <button className="px-2 w-[120px] max-w-[100px] justify-center text-center relative flex font-bold text-[16px] text-white hover:text-gray-200">
                          Actions
                        </button>
                      </span>
                    </nav>
                  </div>
                </div>
                <div className="relative flex flex-row items-center justify-center">
                  <div className="relative flex flex-col items-center bg-gradient-to-r from-white to-[#26f5d8] via-blue-300 rounded-lg py-1 px-1">
                    <div className="z-10 relative flex flex-col items-center w-full border-[8px] bg-[#110a26] border-[#110a26] rounded-lg">
                      <div className="relative z-0 w-full">
                        {/* {rektDivs} */}
                        {rektDivs &&
                          rektDivs.map((item, index) => (
                            <div key={index}>{item}</div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
