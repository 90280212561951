import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import Rektxndiv from "./rektxndiv";
import { ReactComponent as TwitterlogoW } from "../public/images/Xlogo.svg";
import { ReactComponent as TglogoW } from "../public/images/tglogowhite.svg";
import { ReactComponent as LoadingLogo } from "../public/images/loadingblue.svg";

import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";
import { UseUserPortfolio } from "../web3/UserPortfolio";

function PopSubmit(props) {
  const { currChainId } = UseInfoUser();
  const { submissionToken, submissionPower } = UseUserPortfolio();

  // const currChainId = "0x7d0"; //DC
  // const currChainId = "0xfa"; //ftm
  const { sessionEnded, submissionFee, tokenNative } = UseContractInfo();

  const rektxn = props.rektxnpopup;
  const tokenSymbol = props.rektxnpopup[7];
  const tokenNativeAmount = props.rektxnpopup[17];
  const totalWon = props.rektxnpopup[18];

  const [canSubmit, setCanSubmit] = useState(false);

  const [isOpen, setIsOpen] = useState(props.openPopup);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [closeConfirmPopup, setCloseConfirmPopup] = useState(false);

  const [transactionSuccess, setTransactionSuccess] = useState(null);
  const [gettingReceipt, setGettingReceipt] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [checkPairPrice, setCheckPairPrice] = useState(true);
  // const [tokenSymbol, setTokenSymbol] = useState("");
  // const [tokenNative, setTokenNative] = useState("");

  useEffect(() => {
    if (closeConfirmPopup) {
      setTransactionSuccess(null);
      setCheckPairPrice(false);
      setCanSubmit(false);
      // setTokenSymbol("");
      // setTokenNative("");
      // setTokenNativeAmount(0);
    }
  }, [closeConfirmPopup]);

  function ConfirmBtnClicked(txn) {
    setConfirmPopup(true);
  }

  // const {
  //   isLoading,
  //   data,
  //   error: errorPairs,
  //   refetch,
  // } = useQuery({
  //   queryKey: ["Pairs"],
  //   queryFn: () => tokenPairInfo(currChainId),
  //   enabled: checkPairPrice,
  // });

  // useEffect(() => {
  //   if (checkPairPrice) refetch();
  // }, [checkPairPrice]);

  // useEffect(() => {
  //   // console.log(submissionPower, submissionFee);

  // }, [data]);

  async function handleSubmit(txn, pairData) {
    //convert to native everytime
    const buyTxn = txn[10];
    const sellTxn = txn[11];
    const amountRektNative = Math.abs(parseInt(tokenNativeAmount)); //unrektus potential
    // console.log(amountRektNative);

    const { status, message } = await props.handleSubmit(
      buyTxn,
      sellTxn,
      amountRektNative.toString()
    );

    setTransactionSuccess(status);
    setGettingReceipt(false);
    if (status === true) {
      props.success(true);
    } else {
      setErrorMessage(message);
    }
  }

  useEffect(() => {
    if (tokenNativeAmount) {
      console.log("Total won", totalWon);
      console.log("Token native", Math.abs(tokenNativeAmount));
      if (totalWon < Math.abs(tokenNativeAmount)) setCanSubmit(true);
    }
  }, []);

  return (
    <>
      {isOpen && (
        <>
          {sessionEnded && (
            <>
              <div
                // fixed z-20 ml-[-1080.5px] mt-0
                className="fixed z-20 left-0 right-0 top-0 bottom-0 w-screen h-full bg-[#110a26] flex items-center justify-center bg-opacity-80"
                onClick={(e) => {
                  props.closepopup(false);
                }}
              >
                <div
                  className="relative bg-[#110a26] bg-opacity-100 w-[440px] h-fit rounded-3xl flex flex-col items-center justify-center border-[1px] border-[#26f5d8]"
                  onClick={(e) => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                  }}
                >
                  <div className="relative w-full border-b-[1px] border-b-gray-400 flex flex-col items-center justify-center">
                    <p className="relative text-[25px] text-white font-bold py-2 mt-2">
                      Session has ended
                    </p>
                  </div>
                  <div className="flex items-center justify-center py-8 space-x-12">
                    <button
                      className="text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                      onClick={() => props.closepopup(false)}
                    >
                      <p className="text-[16px] font-bold">Go back</p>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {!sessionEnded && (
            <>
              <div
                className="fixed z-20 left-0 right-0 top-0 bottom-0 w-screen h-full bg-[#110a26] flex items-center justify-center bg-opacity-80"
                onClick={() => props.closepopup(false)}
              >
                <div
                  className="relative bg-[#110a26] bg-opacity-100 w-full md:w-[900px] h-fit rounded-3xl flex flex-col items-center justify-center border-[1px] border-[#ff31e9]"
                  onClick={(e) => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                  }}
                >
                  <button
                    className="z-10 absolute h-6 w-6 text-gray-400 rounded-xl cursor-pointer right-4 top-4"
                    onClick={() => props.closepopup(false)}
                  >
                    <p className="z-0 mt-[-2px] uppercase font-bold text-white">
                      x
                    </p>
                  </button>
                  <div className="relative w-full border-b-[1px] border-b-gray-400 flex items-center justify-center">
                    <h1 className="relative text-[22px] md:text-[25px] text-center text-white font-bold py-8 mt-10">
                      Submit this transaction for a vote?
                    </h1>
                  </div>
                  <div className="relative w-full flex items-center justify-center">
                    <h1 className="relative text-[15px] text-white font-bold mt-5">
                      Submission fee:{" "}
                      <span className="text-[#ff31e9]">
                        {submissionFee.toFixed(4)} {submissionToken}
                      </span>
                    </h1>
                  </div>
                  <div className="relative w-full flex items-center justify-center">
                    {submissionPower < submissionFee ? (
                      <>
                        <p className="relative text-[15px] text-red-600 font-bold mt-2 mb-0">
                          Balance: {submissionPower} {submissionToken}
                        </p>
                      </>
                    ) : (
                      <p className="relative text-[15px] text-white font-bold mt-2 mb-0">
                        Balance: {submissionPower} {submissionToken}
                      </p>
                    )}
                  </div>

                  <div className="z-30 border-t-[2px] border-b-[2px] mt-2 border-[#26f5d8] rounded-xl max-content flex flex-col items-center justify-center">
                    <div className="pb-5 px-5 flex flex-wrap justify-start bg-[#191236] rounded-xl">
                      <Rektxndiv rektxn={rektxn} />
                    </div>
                  </div>
                  {/* {!data ? (
                    <>
                      <div className="mt-5 relative w-full flex flex-row items-center justify-center">
                        <p className="relative text-[16px] text-white font-bold py-2 mt-2">
                          Getting pair info from dex...
                        </p>
                      </div>
                      <div className="relative w-full flex flex-row items-center justify-center">
                        {isLoading ? (
                          <h2 className="text-white text-[16px]">
                            is Loading ...
                          </h2>
                        ) : (
                          errorPairs && (
                            <h2 className="text-white text-[16px]">
                              Experiencing network issues?
                            </h2>
                          )
                        )}
                      </div>
                    </>
                  ) : (
                    <> */}
                  <div className="mt-5 relative w-full flex flex-col items-center justify-center">
                    {tokenNativeAmount ? (
                      <>
                        <h2 className="text-[#ff31e9] text-[16px]">
                          {parseInt(rektxn[13])} {tokenSymbol} ={" "}
                          {tokenNativeAmount.toFixed()} {tokenNative}
                        </h2>
                      </>
                    ) : (
                      <>
                        <h2 className="text-[#ff31e9] text-[16px]">
                          <>Exchange rate not available</>
                        </h2>
                        <p className="text-[#ff31e9] text-[12px]">
                          Not eligible for submission
                        </p>
                      </>
                    )}
                  </div>
                  <div className="mt-1 relative w-full flex flex-col items-center justify-center">
                    {canSubmit ? (
                      <>
                        <p className="relative text-[12px] text-[#23ff7b] font-bold py-2 mt-2">
                          This trxn is eligible for submission
                        </p>
                        <p className="relative text-[12px] text-white font-bold py-1">
                          Can re-submit until Rekt Amount has been collected in
                          full
                        </p>
                      </>
                    ) : (
                      <>
                        {tokenNativeAmount && (
                          <>
                            <p className="relative text-[12px] text-[#ff31e9] font-bold py-2 mt-2">
                              This trxn cannot be submitted
                            </p>
                            <p className="relative text-[12px] text-white font-bold py-2">
                              This trxn is maxed out in winning
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  {/* </>
                  )} */}
                  <div className="mt-5 relative w-full flex flex-wrap items-center justify-center py-8 mb-8">
                    <span className="mb-5 mt-[-50px] flex w-full items-center justify-between px-4 md:px-14">
                      <button
                        className="text-white bg-[#110a26] border-[1px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                        onClick={() => props.closepopup(false)}
                      >
                        <p className="text-[16px] font-bold">Go back</p>
                      </button>
                      {tokenNativeAmount && canSubmit ? (
                        <button
                          className="text-white bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 border-[1px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                          onClick={() => {
                            ConfirmBtnClicked(rektxn);
                            setCloseConfirmPopup(false);
                            // updateDB();
                          }}
                        >
                          <p className="text-[16px] font-bold animate-pulse">
                            Submit
                          </p>
                        </button>
                      ) : (
                        <></>
                      )}
                    </span>
                    <span className="mb-5 flex w-full items-center justify-center">
                      <div className="flex flex-row items-center justify-center px-5 space-x-14">
                        <button
                          className=" flex flex-row absolute x-space-2 items-center justify-center"
                          onClick={() =>
                            window.open(
                              `https://twitter.com/intent/tweet?text=I%20got%20REKT%20by%20${
                                rektxn[12] < 1
                                  ? -(100 * (1 - rektxn[12])).toFixed(2)
                                  : (100 * rektxn[12]).toFixed(2)
                              }%25%20and%20lost%20${parseInt(rektxn[13])}%20${
                                rektxn[7]
                              }%0A%0ALet's%20get%20UNREKT%21%20%0A%0AOnly%20at%20unrektus.fi`
                            )
                          }
                        >
                          <TwitterlogoW className="absolute h-4 w-4"></TwitterlogoW>
                        </button>
                      </div>
                      <div className="flex flex-row items-center justify-center px-5 space-x-14">
                        <button
                          className=" flex flex-row absolute x-space-2 items-center justify-center"
                          onClick={() =>
                            window.open(
                              `tg://msg_url?url=unrektus.fi&text=I%20got%20REKT%20by%20${
                                rektxn[12] < 1
                                  ? -(100 * (1 - rektxn[12])).toFixed(2)
                                  : (100 * rektxn[12]).toFixed(2)
                              }%25%20and%20lost%20${parseInt(rektxn[13])}%20${
                                rektxn[7]
                              }%0ALet's%20get%20UNREKT%21%20%0AOnly%20at%20unrektus.fi`
                            )
                          }
                        >
                          <TglogoW className="absolute h-4 w-4"></TglogoW>
                        </button>
                        {/* <a href='tg://msg_url?url=unrektus.fi&text=aloagain'>Share</a> */}
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {confirmPopup && !closeConfirmPopup && (
        <div
          // fixed z-20 ml-[-1080.5px] mt-0
          className="fixed z-20 left-0 top-0 w-screen h-screen bg-[#110a26] flex items-center justify-center bg-opacity-80"
          onClick={() => {
            if (transactionSuccess !== null) {
              setCloseConfirmPopup(true);
              props.closepopup(false);
            } else {
              setCloseConfirmPopup(true);
            }
          }}
        >
          <div
            className="relative bg-[#110a26] bg-opacity-100 w-[440px] h-[250px] rounded-3xl flex flex-col items-center justify-center border-[1px] border-[#26f5d8]"
            onClick={(e) => {
              // do not close modal if anything inside modal content is clicked
              e.stopPropagation();
            }}
          >
            {submissionPower !== 0 && submissionPower >= submissionFee ? (
              <>
                <button
                  className="z-10 absolute h-6 w-6 text-gray-400 rounded-xl cursor-pointer right-4 top-4"
                  onClick={() => {
                    if (transactionSuccess !== null) {
                      setCloseConfirmPopup(true);
                      props.closepopup(false);
                    } else {
                      setCloseConfirmPopup(true);
                    }
                  }}
                >
                  <p className="z-0 mt-[-2px] uppercase font-bold text-white">
                    x
                  </p>
                </button>
                {tokenNativeAmount ? (
                  <>
                    <div className="relative w-full border-b-[1px] border-b-gray-400 flex flex-row items-center justify-center">
                      <p className="relative text-[25px] text-white font-bold py-2 mt-2">
                        Confirm your submission
                      </p>
                    </div>
                    <div className="relative w-full flex items-center justify-center">
                      <p className="relative text-[15px] text-[#ff31e9] font-bold mt-2 mb-0">
                        You will be submitting using {submissionFee.toFixed(4)}{" "}
                        {submissionToken}
                      </p>
                    </div>
                    <div className="flex items-center justify-center py-8 space-x-12">
                      <button
                        className="text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                        onClick={() => {
                          if (transactionSuccess !== null) {
                            setCloseConfirmPopup(true);
                            props.closepopup(false);
                          } else {
                            setCloseConfirmPopup(true);
                          }
                        }}
                      >
                        <p className="text-[16px] font-bold">Go back</p>
                      </button>{" "}
                      <button
                        className="text-white bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                        onClick={() => {
                          setGettingReceipt(true);
                          handleSubmit(rektxn);
                          // setCloseConfirmPopup(true);
                          // props.closepopup(false);
                        }}
                      >
                        <label className="z-20 text-[16px] font-bold animate-pulse hover:cursor-pointer">
                          Confirm
                        </label>
                        <div className="z-0 absolute rounded-lg h-[40px] w-[80px] border-4 blur border-[#d430b0]"></div>
                      </button>
                    </div>
                    {gettingReceipt ? (
                      <>
                        <LoadingLogo className="h-[50px] w-[50px] animate-spin"></LoadingLogo>
                      </>
                    ) : (
                      <div className="flex flex-col items-center justify-center">
                        {transactionSuccess != null &&
                          transactionSuccess === false && (
                            <>
                              <label className="z-20 text-[#d430b0] text-[16px] font-bold">
                                Transaction failed
                              </label>
                              <label className="z-20 text-[#d430b0] text-[16px] font-bold">
                                {errorMessage}
                              </label>
                            </>
                          )}
                        {transactionSuccess != null &&
                          transactionSuccess === true && (
                            <label className="z-20 text-[#26f5d8] text-[16px] font-bold ">
                              Transaction success
                            </label>
                          )}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="relative w-full border-b-[1px] border-b-gray-400 flex flex-row items-center justify-center">
                      <p className="relative text-[25px] text-white font-bold py-2 mt-2">
                        Confirm your submission
                      </p>
                    </div>
                    <div className="relative w-full flex items-center justify-center">
                      <p className="relative text-[15px] text-[#ff31e9] font-bold mt-2 mb-0">
                        Exchange Rate Not available for token REKT
                      </p>
                    </div>
                    {/* <div className="relative w-full flex items-center justify-center">
                      <p className="relative text-[15px] text-[#ff31e9] text-center font-bold mt-2 mb-0">
                        Cannot Submit this transaction.<br></br>Please use
                        another one.
                      </p>
                    </div> */}
                    <div className="flex items-center justify-center py-8 space-x-12 mb-8">
                      <button
                        className="text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                        onClick={() => {
                          if (transactionSuccess !== null) {
                            setCloseConfirmPopup(true);
                            props.closepopup(false);
                          } else {
                            props.closepopup(false);
                            setCloseConfirmPopup(true);
                          }
                        }}
                      >
                        <p className="text-[16px] font-bold">Go back</p>
                      </button>{" "}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="relative w-full border-b-[1px] border-b-gray-400 flex flex-row items-center justify-center">
                  <p className="relative text-[16px] text-white font-bold py-8 mt-10">
                    Not enough {submissionToken} for submission fee
                  </p>
                </div>
                <div className="flex items-center justify-center py-8 space-x-12 mb-8">
                  <button
                    className="text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                    onClick={() => {
                      if (transactionSuccess !== null) {
                        setCloseConfirmPopup(true);
                        props.closepopup(false);
                      } else {
                        props.closepopup(false);
                        setCloseConfirmPopup(true);
                      }
                    }}
                  >
                    <p className="text-[16px] font-bold">Go back</p>
                  </button>{" "}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default PopSubmit;
