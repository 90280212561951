import React, { useState, useEffect, useContext, useCallback } from "react";

import {
  useAccountEffect,
  useConnect,
  useNetwork,
  usePublicClient,
} from "wagmi";

import { ethers } from "ethers";
import { REKT_ADD, USDC_ADD } from "./config.js";
export const InfoUser = React.createContext();
// hook
export function UseInfoUser() {
  return useContext(InfoUser);
}

export function Web3Context({ children }) {
  const [connected, setConnected] = useState(false);
  const [currAddress, setAddress] = useState("");
  const [connector, setConnector] = useState(null);
  const [currChainId, setChainId] = useState("");
  const [currChainName, setCurrentChainName] = useState("");
  const [comingSoon, setComingSoon] = useState(true);
  const [wrongNetwork, setWrongNetwork] = useState(true);
  const [currProvider, setCurrProvider] = useState(null);
  const [currSigner, setCurrSigner] = useState(null);

  //   const publicClient = usePublicClient();
  const checkComingSoon = (chain) => {
    if (
      chain !== "0x1" //eth
      // chain !== "0xfa" && //ftm
      // chain !== "0xfaa5" //sonic
      // chain !== "0x7d0" //dc main
      // chain !== "0x238" //doge test
      // chain !== "0x7a69" // hardhat
    ) {
      setComingSoon(false);
      return false;
    } else {
      console.log("Coming Soon on chain ", chain);
      setComingSoon(true);
      return true;
    }
  };

  const checkNetwork = (chain) => {
    if (
      chain !== "0x1" && //eth
      chain !== "0xfa" && //ftm
      chain !== "0xfaa5" && //sonic test
      chain !== "0x7d0" && //doge
      chain !== "0x238" && //doge test
      chain !== "0x7a69" // hardhat
    ) {
      console.log("wrong network connected");
      setWrongNetwork(true);
      setComingSoon(false);
      return true;
    } else {
      console.log("connected to ", chain);
      setWrongNetwork(false);
      return false;
    }
  };

  async function handleChainChange(newChain) {
    // console.log("handleChainChange");
    if (newChain !== currChainId) {
      console.log("chain  changed to " + newChain);
      checkNetwork(newChain);
      checkComingSoon(newChain);
      setChainId(newChain);
      // if (checkNetwork(newChain) === false) {
      //   checkComingSoon(newChain);
      //   setChainId(newChain);
      // }
    }
  }
  async function handleAddressChange(newAccount) {
    // console.log("handleAddressChange");
    if (newAccount[0] != currAddress) {
      console.log("account  changed to  " + newAccount[0]);
      setAddress(newAccount[0]);
    }
  }
  const createProvider = async (web3Provider) => {
    let provider;
    if (web3Provider) {
      console.log("provider with wallet connect");
      // console.log("create current provider");
      provider = new ethers.BrowserProvider(web3Provider, "any");
      setCurrProvider(provider);
    } else {
      const chain = await window.ethereum.request({ method: "eth_chainId" });
      // console.log(typeof chain, chain);
      checkNetwork(String(chain));
      checkComingSoon(chain);
      setChainId(chain);

      console.log("provider with window.ethereum");
      provider = new ethers.BrowserProvider(window.ethereum, "any");
      setCurrProvider(provider);
      // console.log(provider);
    }

    return provider;
  };

  const createSigner = async () => {
    if (currProvider) {
      // console.log("create current signer");
      const accounts = await currProvider.send("eth_requestAccounts", []);
      const account = accounts[0];
      const signer = await currProvider.getSigner();
      // console.log(signer);
      setCurrSigner(signer);
      return signer;
    }
  };

  useEffect(() => {
    if (!connected) {
      if (window.ethereum) {
        createProvider();
      }
    }
  }, [connected]);

  useEffect(() => {
    // setCurrProvider(null);
    // createProvider(currProvider);
    const doStuff = async () => {
      if (connected && connector) {
        const provider = await connector.getProvider();
        provider.on("accountsChanged", handleAddressChange);
        provider.on("chainChanged", handleChainChange);
        // console.log(provider);
        // console.log(provider.chainId);
        // setChainId(provider.chainId);
        await createProvider(provider);
      }
    };
    doStuff();
    // createSigner();
  }, [currChainId]);

  useEffect(() => {
    setCurrSigner(null);
    const doStuff = async () => {
      await createSigner();
    };
    doStuff();
  }, [currAddress]);

  useAccountEffect({
    onConnect({ address, connector, isReconnected }) {
      // console.log("Connected", { address, connector, isReconnected });
      console.log("Connected to", address);
      //   console.log("Chain", chain);
      //   console.log(provider);
      const setData = async () => {
        setConnector(connector);
        const provider = await connector.getProvider();
        provider.on("accountsChanged", handleAddressChange);
        provider.on("chainChanged", handleChainChange);
        // console.log(provider);
        // console.log(provider.chainId);
        // setChainId(provider.chainId);
        // createProvider(provider);

        checkNetwork(provider.chainId);
        checkComingSoon(provider.chainId);
        setChainId(provider.chainId);

        //createSigner();
        setConnected(true);
        setAddress(address);
        // connector.on("change", handleChange);
      };
      setData();
    },
    onDisconnect() {
      console.log("wallet disconnected");
      setConnected(false);
      setConnector(null);
    },
  });

  return (
    <>
      <InfoUser.Provider
        value={{
          connected,
          currAddress,
          currChainId,
          wrongNetwork,
          comingSoon,
          currProvider,
          currSigner,
        }}
      >
        {children}
      </InfoUser.Provider>
    </>
  );
}
