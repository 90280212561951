import React, { useState, useEffect, useCallback, useContext } from "react";
import { ReactComponent as Logo } from "../public/images/sortPyramids.svg";
import { ReactComponent as LoadingLogo } from "../public/images/loadingblue.svg";

import PopSubmit from "../components/popSubmit";
import RektData from "../components/rektData";

import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";
import { UseUserPortfolio } from "../web3/UserPortfolio";

import { FaunaDatabase, UseFaunaDatabase } from "../utils/Database";

export function Submit({ children }) {
  const {
    connected,
    currAddress,
    currChainId,
    wrongNetwork,
    comingSoon,
    currProvider,
    currSigner,
  } = UseInfoUser();
  const {
    initiated,
    unrektusSession,
    unrektusContract,
    currentStatus,
    checkStatus,
    sessionEnded,
    handleSubmit,
    getSubmissionPropertiesAll,
  } = UseContractInfo();
  const {
    rektPower,
    getRektPower,
    unRektness,
    setUnRektness,
    dataChain,
    data,
    isLoading,
    errorTxn,
    queriesEnable,
    setQueriesEnable,
    howRektPressed,
    setHowRektPressed,
    dataSonic,
    isLoadingSonic,
    errortxnSonic,
    queriesEnableSonic,
    userCurrentSubmission,
    getUserPastSubmissions,
  } = UseUserPortfolio();
  const { sessionExists, addSessionData, updateSessionData } =
    UseFaunaDatabase();

  const forceRender = useCallback(() => updateState({}), []);
  const [, updateState] = useState();

  const [goAutoparse, setGoAutoParse] = useState(false);

  const [rektxnpopup, setrektxnpopup] = useState(0);
  const [popupSubmit, setPopupSubmit] = useState(false);
  const [transactionIsSuccess, setTransactionIsSuccess] = useState(null);

  // const [addi, setAddi] = useState(currAddress);
  // const [chain, setChainId] = useState(currChainId);

  //   useEffect(() => {
  //     if (addi != currAddress) setSigner(currAddress);
  //     if (chain != currChainId) setChainId(currChainId);
  //   }, [currAddress, currChainId]);
  function handleSubmitPop(rektTxn) {
    console.log("click");
    setPopupSubmit(true);
    setrektxnpopup(rektTxn);
    setTransactionIsSuccess(null);
  }

  const handleCheckSubmission = async () => {
    // console.log("handleCheckSubmission");
    // const alreadySubmitted = false;
    // const latestSubmissionSessionId = 0;
    var userInfo = {
      alreadySubmitted: false,
      currentSubmissionCounts: 0,
      currentSubmissionInfo: [],
      won: false,
      wonSubmissions: [],
    };
    if (unrektusContract) {
      const { userCurrentSubmission, userPastSubmission } =
        await getUserPastSubmissions();

      // console.log(userCurrentSubmission);
      // console.log(userPastSubmission);
      userInfo = {
        alreadySubmitted: userCurrentSubmission.alreadySubmitted,
        currentSubmissionCounts: userCurrentSubmission.currentSubmissionCounts,
        currentSubmissionInfo: userCurrentSubmission.currentSubmissionInfo,
        won: userPastSubmission.won,
        wonSubmissions: userPastSubmission.wonSubmissions,
      };
      // console.log(
      //   "already submitted",
      //   userInfo.alreadySubmitted,
      //   userInfo.currentSubmissionCounts
      // );
      // console.log(currentSubmissionInfo);
      // console.log("won", userInfo.won);
      // console.log(userInfo.wonSubmissions);
      // console.log(userCurrentSubmission);
      // console.log(userPastSubmission);
    }
    return userInfo;
  };

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      if (transactionIsSuccess === true) {
        // console.log("transaction succcess... reloading divs");
        checkStatus();
        getSubmissionPropertiesAll();
        setGoAutoParse(true);
      }
      // setRektDivs([]);
      // setrektTxns([]);
      // setNbRekt(0);
      // setNbMadeit(0);
      // setUnrektNumber(0);
    }
    return () => {
      isCurrent = false;
    };
  }, [transactionIsSuccess]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      if (howRektPressed > 0) {
        // console.log("changeDetected... reloading divs");
        // console.log(howRektPressed);
        setGoAutoParse(true);
      }
      return () => {
        isCurrent = false;
      };
    }
  }, [data]);

  useEffect(() => {
    if (currentStatus) {
      if (howRektPressed > 0) {
        setGoAutoParse(true);
      }
    }
  }, [currentStatus]);

  function disbaleScroll() {
    if (popupSubmit) {
      document.body.style.overflow = "hidden";
    }
  }

  function enableScroll() {
    if (!popupSubmit) {
      document.body.style.overflow = "initial";
    }
  }

  return (
    <>
      <div className="mt-5 w-full flex flex-col justify-center ">
        {!connected && (
          <div className="flex flex-col md:flex-row justify-center items-center">
            <h1 className="text-[#ff31e9] text-[16px] md:text-[24px] font-bold">
              Connect Wallet&nbsp;
            </h1>
            <h1 className="text-white text-[16px] md:text-[24px] font-bold">
              to discover your&nbsp;
            </h1>
            <h1 className="text-[#26f5d8] text-[16px] md:text-[24px] font-bold">
              Unrektus potential!
            </h1>
          </div>
        )}
        {comingSoon && (
          <div className="flex flex-row justify-center ">
            <div className=" flex flex-col justify-center mb-[50px] ">
              <h1 className="text-[#23ff7b] text-[20px] font-bold">
                Coming Soon on this chain
              </h1>
            </div>
          </div>
        )}
        {connected && !wrongNetwork && !comingSoon && (
          <>
            {popupSubmit && (
              <PopSubmit
                openPopup={popupSubmit}
                closepopup={setPopupSubmit}
                rektxnpopup={rektxnpopup}
                handleSubmit={handleSubmit}
                success={setTransactionIsSuccess}
              >
                {" "}
                {disbaleScroll()}
              </PopSubmit>
            )}
            {!popupSubmit && enableScroll()}

            <div className="px-3 py-3 w-full flex flex-col items-center justify-center">
              {currChainId === "0xfaa5" && howRektPressed > 0 && (
                <>
                  <RektData
                    dataChain={"Sonic"}
                    data={dataSonic}
                    queriesEnable={queriesEnableSonic}
                    isLoading={isLoadingSonic}
                    errorTxn={errortxnSonic}
                    goAutoparse={goAutoparse}
                    setGoAutoParse={setGoAutoParse}
                    howRektPressed={howRektPressed}
                    setHowRektPressed={setHowRektPressed}
                    handleCheckSubmission={handleCheckSubmission}
                    handleSubmitPop={handleSubmitPop}
                  ></RektData>
                </>
              )}
              <RektData
                dataChain={dataChain}
                data={data}
                queriesEnable={queriesEnable}
                isLoading={isLoading}
                errorTxn={errorTxn}
                goAutoparse={goAutoparse}
                setGoAutoParse={setGoAutoParse}
                howRektPressed={howRektPressed}
                setHowRektPressed={setHowRektPressed}
                handleCheckSubmission={handleCheckSubmission}
                handleSubmitPop={handleSubmitPop}
              ></RektData>
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default Submit;
